import api from "../helpers/api-services";
import React, { Component } from "react";
import PageLoader from "../../pages/common/helpers/page-loader";
import { UniversalConnecterURLProps } from "../../pages/common/settings/universal-connecter-urls";
import {
  authenticateZoomTokenSignin,
  authenticateZoomAccessTokenSignin,
} from "../actions/identity-actions";
import ResponseUtil from "../helpers/response-util";
import IntlUtil from "../helpers/intl-util";
import PageUtil from "../helpers/page-util";
import RequestUtil from "../helpers/request-util";
import TokenUtil from "../helpers/token-util";
import { AppConfigProps } from "../settings/app-config";
import { AppURLProps } from "../settings/app-urls";

class ZoomAuthenticate extends Component {
  _intl_ns_common = "suc_common";
  _intl_ns_signin = "signin";
  _isMounted = false;
  _axiosSource = api.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
    };
  }
  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };
  async componentDidMount() {
    this._isMounted = true;
    PageUtil.scrollToTop();
    let URLResponse = new URLSearchParams(this.props.history.location.search);
    let code = URLResponse.get("code");
    if (code !== null && code !== undefined) {
      await this.setStateAsync({ isPageDataFetched: false });
      await this.handleZoomSignin(code);
      await this.setStateAsync({ isPageDataFetched: true });
    }
  }
  handleZoomSignin = async (code) => {
    await this.setStateAsync({ isFormDataSubmitted: true });
    let zoomCode = {
      code: code,
      redirectUrl: process.env.REACT_APP_ZOOM_REDIRECT_URL,
    };
    let loginDetails = JSON.parse(localStorage.getItem("loginDetailsHint"));
    if (loginDetails?.loginType === "zoomInDirectLogin") {
      await authenticateZoomTokenSignin(zoomCode, this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.result
          ) {
            if (res.data.result.accessToken && res.data.result.refreshToken) {
              TokenUtil.setIdentityToken(
                res.data.result.accessToken,
                res.data.result.refreshToken
              );
              await RequestUtil.setRequestHeaders();
            }
            await this.setStateAsync({ isPageDataFetched: true });
            await this.props.history.push(
              UniversalConnecterURLProps.numberManagement.numberList
            );
          }
        })
        .catch(async (err) => {
          await this.setStateAsync({ isPageDataFetched: true });
          await this.setStateAsync({ isErrorOccurred: true });
          //access log failure
          let errorMessage = IntlUtil.getText(
            this._intl_ns_signin,
            "notification.error.signinIssueUnknown"
          );
          if (
            err &&
            err.status &&
            err.status === AppConfigProps.httpStatusCode.badRequest
          ) {
            let primeError = ResponseUtil.getPrimeError(err);
            if (primeError && primeError.code === "E10003") {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.accountNotFound"
              );
            } else {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.signinIssueUnknown"
              );
            }
          } else if (
            err &&
            err.status &&
            err.status === AppConfigProps.httpStatusCode.unauthorized
          ) {
            let primeError = ResponseUtil.getPrimeError(err);
            if (primeError && primeError.code === "E10003") {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.accountNotExist"
              );
            } else {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.signinIssueUnknown"
              );
            }
          }
          await this.props.history.push({
            pathname: AppURLProps.signin,
            state: {
              errorMessage: errorMessage,
              isErrorOccurred: true,
            },
          });
        });
    } else {
      await authenticateZoomAccessTokenSignin(zoomCode, this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.result
          ) {
            if (res.data.result.accessToken && res.data.result.refreshToken) {
              TokenUtil.setIdentityToken(
                res.data.result.accessToken,
                res.data.result.refreshToken
              );
              await RequestUtil.setRequestHeaders();
            }
            await this.setStateAsync({ isPageDataFetched: true });
            await this.props.history.push(
              UniversalConnecterURLProps.numberManagement.numberList
            );
          }
        })
        .catch(async (err) => {
          await this.setStateAsync({ isPageDataFetched: true });
          await this.setStateAsync({ isErrorOccurred: true });
          //access log failure
          let errorMessage = IntlUtil.getText(
            this._intl_ns_signin,
            "notification.error.signinIssueUnknown"
          );
          if (
            err &&
            err.status &&
            err.status === AppConfigProps.httpStatusCode.badRequest
          ) {
            let primeError = ResponseUtil.getPrimeError(err);
            if (primeError && primeError.code === "E10003") {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.accountNotFound"
              );
            } else {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.signinIssueUnknown"
              );
            }
          } else if (
            err &&
            err.status &&
            err.status === AppConfigProps.httpStatusCode.unauthorized
          ) {
            let primeError = ResponseUtil.getPrimeError(err);
            if (primeError && primeError.code === "E10003") {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.accountNotExist"
              );
            } else {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.signinIssueUnknown"
              );
            }
          }
          await this.props.history.push({
            pathname: AppURLProps.signin,
            state: {
              errorMessage: errorMessage,
              isErrorOccurred: true,
            },
          });
        });
    }
  };

  render() {
    return (
      <>
        <div id="suc-layout-wrapper">
          <PageLoader
            status={this.state.isPageDataFetched}
            size="medium"
            labelPosition="below"
            label={IntlUtil.getText(this._intl_ns_common, "content.loading")}
            type="full"
          >
            <div></div>
          </PageLoader>
        </div>
      </>
    );
  }
}

export default ZoomAuthenticate;
