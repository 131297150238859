import axios from "axios";
import TokenUtil from "../helpers/token-util";

const instance = axios;

instance.interceptors.request.use(
  async (config) => {
    const token = TokenUtil.getIdentitySippioAccessToken();
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

export default instance;
