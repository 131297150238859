
import Home from "../pages/common/components/home";
import NumberList from "../pages/numbers/components/number-list";
import { UniversalConnecterURLProps } from "../pages/common/settings/universal-connecter-urls";

export const AppRoutes=[
    {
        expanded: false,
        component: Home,
        url: UniversalConnecterURLProps.index,
        name: "Index",
        navDisplay: false,
        exactPath: true,
      },
      {
        expanded: false,
        component: NumberList,
        url: UniversalConnecterURLProps.numberManagement.numberList,
        name: "Number List",
        navDisplay: false,
        exactPath: true,
      },
    //   {
    //   expanded: false,
    //   component: ZoomSignin,
    //   url: AppURLProps.zoomSignin,
    //   name: "Number List",
    //   navDisplay: false,
    //   exactPath: true,
    // },
    // {
    //   expanded: false,
    //   component: ZoomAuthenticate,
    //   url: AppURLProps.zoomAuthenticate,
    //   name: "Number List",
    //   navDisplay: false,
    //   exactPath: true,
    // },
]