// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const msalConfig = {
  auth: {
    postLogoutRedirectUri: window.location.origin + "/signin",
    knownAuthorities: [
      "login.microsoftonline.com",
      "phoneconnectdev.b2clogin.com",
      "phoneconnectuat.b2clogin.com",
      "phoneconnectprod.b2clogin.com",
    ],
    redirectUri: window.location.origin + "/authenticate",
    navigateToLoginRequestUrl: false,
    validateAuthority: false,
  },
  cache: {
    storeAuthStateInCookie: isIE || isEdge || isFirefox || true,
    cacheLocation: "localStorage",
    secureCookies: isIE || isEdge || isFirefox,
  },
  system: {
    // asyncPopups: true,
    iframeHashTimeout: 60000,
    allowRedirectInIframe: true,
  },
};

export const loginRequest = {
  scopes: ["https://graph.microsoft.com/.default"],
  prompt: "select_account",
  forceRefresh: true,
  extraQueryParameters: { campaignId: "default" },
};

export const logoutRequest = {
  mainWindowRedirectUri: window.location.origin + "/signin",
};
