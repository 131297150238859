

import React, { Fragment } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../settings/azure-insights-config.js';
import { withRouter } from 'react-router-dom';


const AzureInsitghtsProvider = (props) => {
    const { children } = props;
    return (
        <Fragment>
            {children}
        </Fragment>
    )
}

export default withRouter(withAITracking(reactPlugin, AzureInsitghtsProvider));