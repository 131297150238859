/**
Project: Phone Connect (c)
Title: Signin 
Description: Component for displaying the sign in page 
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import React, { Component } from "react";
import { connect } from "react-redux";
import api from "../helpers/api-services";
import { Text, PrimaryButton, ProgressIndicator } from "@fluentui/react";
import { PublicClientApplication } from "@azure/msal-browser";
import IntlUtil from "../helpers/intl-util";
import { msalConfig, loginRequest } from "../settings/msal-config";
import { AppPageTitle } from "../components/app-page-title";
import { ReleaseGateIcon } from "@fluentui/react-icons";
import { UniversalConnecterURLProps } from "../../pages/common/settings/universal-connecter-urls";
import {
  authenticateTokenSignin,
  setIdentityProfile,
} from "../actions/identity-actions";
import { AppConfigProps } from "../settings/app-config";
import ResponseUtil from "../helpers/response-util";
import TokenUtil from "../helpers/token-util";
import { setLoginType } from "../actions/identity-actions";

class Signin extends Component {
  _isMounted = false;
  _axiosSource = api.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _intl_ns_common = "common";
  _intl_ns_signin = "signin";

  constructor(props) {
    super(props);
    this.state = {
      isFormDataSubmitted: false,
      errorMessage: null,
      showAccounts: false,
      isErrorOccurred: false,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    let browserReloadInfo =
      window.performance.getEntriesByType("navigation")[0];
    if (browserReloadInfo.type === "reload") {
      this.clearErrorData();
    }
  }

  async componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      IntlUtil.getText(
        this._intl_ns_common,
        "notification.warning.requestCancelled"
      )
    );
  }

  clearErrorData = async () => {
    await this.props.history.replace({
      ...this.props.history.location,
      state: {
        errorMessage: null,
        isErrorOccurred: false,
      },
    });
    await this.setStateAsync({ isErrorOccurred: true });
  };

  handleInteractiveSignin = async (accessToken, refreshToken, history) => {
    await this.setStateAsync({ isFormDataSubmitted: true });
    await authenticateTokenSignin(
      accessToken,
      { refreshToken: refreshToken },
      this._cancelToken
    )
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ isFormDataSubmitted: false });
          if (res.data.result.accessToken && res.data.result.refreshToken) {
            TokenUtil.setIdentityToken(
              res.data.result.accessToken,
              res.data.result.refreshToken
            );
            await this.props.history.push(UniversalConnecterURLProps.home);
          }
        }
      })
      .catch(async (err) => {
        await this.setStateAsync({ isFormDataSubmitted: false });
        await this.setStateAsync({
          isErrorOccurred: true,
        });
        //access log failure
        let errorMessage = IntlUtil.getText(
          this._intl_ns_signin,
          "notification.error.signinIssueUnknown"
        );
        if (err && err.status) {
          if (err.status === AppConfigProps.httpStatusCode.badRequest) {
            let primeError = ResponseUtil.getPrimeError(err);
            if (primeError && primeError.code === "E10003") {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.accountNotFound"
              );
            } else {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.signinIssueUnknown"
              );
            }
          } else if (
            err.status === AppConfigProps.httpStatusCode.unauthorized
          ) {
            let primeError = ResponseUtil.getPrimeError(err);
            if (primeError && primeError.code === "E10003") {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.accountNotExist"
              );
            } else if (
              primeError &&
              (primeError.code === "E10022" || primeError.code === "E10021")
            ) {
              errorMessage = primeError.message;
            } else if (primeError && primeError.code === "E10004") {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.signinUnauthorized"
              );
            } else {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.signinIssueUnknown"
              );
            }
          } else if (err.status === AppConfigProps.httpStatusCode.forbidden) {
            errorMessage =
              this.state.userName +
              ", " +
              IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.signinForbidden"
              );
            await this.setStateAsync({
              isErrorOccurred: true,
            });
          } else {
            errorMessage = IntlUtil.getText(
              this._intl_ns_signin,
              "notification.error.signinIssueUnknown"
            );
          }
        }
        await this.setStateAsync({ errorMessage: errorMessage });
      });
  };

  handlePopupSignin = async (loginDetailsHint) => {
    localStorage.clear();
    await this.clearErrorData();
    localStorage.setItem("loginDetailsHint", JSON.stringify(loginDetailsHint));
    if (loginDetailsHint?.loginType === "zoomInDirectLogin") {
      let msalConfigObj = msalConfig;
      let clientTokenRequest = loginRequest;
      let loginDetails = JSON.parse(localStorage.getItem("loginDetailsHint"));
      if (loginDetails?.loginProvider?.toLowerCase() !== "microsoft.com") {
        let azureActiveDirectoryClientAppId =
          process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_B2C_APP_ID;
        let azureActiveDirectoryB2CMicrosoftAuthority =
          process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_MICROSOFT_B2C_URL;
        msalConfigObj = {
          ...msalConfig,
          auth: {
            ...msalConfig.auth,
            clientId: azureActiveDirectoryClientAppId,
            authority: azureActiveDirectoryB2CMicrosoftAuthority,
          },
        };
        clientTokenRequest = {
          ...loginRequest,
          extraQueryParameters: {
            ...loginRequest.extraQueryParameters,
            domain_hint: loginDetails?.loginProvider,
            prompt: "select_account",
          },
        };
      } else {
        let azureActiveDirectoryClientAppId =
          process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_ID;
        let azureActiveDirectoryMicrosoftAuthority =
          process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_MICROSOFT_URL;
        msalConfigObj = {
          ...msalConfig,
          auth: {
            ...msalConfig.auth,
            clientId: azureActiveDirectoryClientAppId,
            authority: azureActiveDirectoryMicrosoftAuthority,
          },
        };
        clientTokenRequest = loginRequest;
      }

      let msal = new PublicClientApplication(msalConfigObj);
      await msal
        .loginRedirect(clientTokenRequest)
        .then(async (res) => {
          msal.setActiveAccount(res.account);
          msal.setActiveAccount(
            msal.getAccountByHomeId(res.account.homeAccountId)
          );
          await this.setStateAsync({ userName: res.account.username });
        })
        .catch(async (err) => {
          this.handleRedirect(msal);
        });
    } else {
      this.handleZoomLogin();
    }
  };

  handleRedirect = (msal) => {
    let accounts = msal.getAllAccounts();
    if (accounts.length > 0) {
      msal.setActiveAccount(accounts[0]);
    }
    msal.addEventCallback(
      (event) => {
        if (event.eventType === "LOGIN_SUCCESS" && event.payload.account) {
          const account = event.payload.account;
          msal.setActiveAccount(account);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    msal
      .handleRedirectPromise()
      .then((res) => {
        const account = msal.getActiveAccount();
        if (!account) {
          msal.loginRedirect(loginRequest);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleZoomLogin = () => {
    const clientId = process.env.REACT_APP_ZOOM_CLIENT_ID;
    const thisUrl = new URL(process.env.REACT_APP_ZOOM_AUTH_URL);
    thisUrl.searchParams.set("response_type", "code");
    thisUrl.searchParams.set("client_id", clientId);
    thisUrl.searchParams.set(
      "redirect_uri",
      window.location.origin + "/zoom-authenticate"
    );
    window.location.href = thisUrl.href;
  };

  render() {
    return (
      <>
        <AppPageTitle
          pageTitle={IntlUtil.getText(this._intl_ns_signin, "title.signin")}
        />
        <div className="signin-page-wrapper">
          <div className="signin-container-wrapper">
            <div className="signin-content-wrapper">
              <div className="signin-logo-wrapper">
                <Text className="signin-brand">
                  {IntlUtil.getText(this._intl_ns_signin, "content.appName")}
                </Text>
              </div>

              <div className=" signin-title-wrapper">
                <span className="signin-page-icon-wrapper">
                  <ReleaseGateIcon className="signin-button-icon" />
                </span>
                <div className="signin-page-text">
                  <Text className="signin-title">
                    {IntlUtil.getText(
                      this._intl_ns_signin,
                      "content.signinInfoText"
                    )}
                  </Text>
                </div>
              </div>
              {this.state.isErrorOccurred === true ? (
                <span className="signin-error-text m-b-5">
                  {this.state.errorMessage}
                </span>
              ) : null}
              {this.props.history.location.state &&
              this.props.history.location.state?.isErrorOccurred === true ? (
                <span className="signin-error-text m-b-5">
                  {this.props.history.location.state?.errorMessage}
                </span>
              ) : null}
              <div className="signin-button-wrapper m-t-5 m-b-5">
                <PrimaryButton
                  className="signin-button"
                  text={IntlUtil.getText(
                    this._intl_ns_signin,
                    "content.signin"
                  )}
                  disabled={this.state.isFormDataSubmitted}
                  onClick={() => {
                    this.setStateAsync({
                      isFormDataSubmitted: true,
                      errorMessage: null,
                      isErrorOccurred: false,
                    });
                    let loginDetails = {
                      loginType: "zoomInDirectLogin",
                      loginProvider: "microsoft.com",
                    };
                    this.handlePopupSignin(loginDetails);
                  }}
                />
              </div>
              {this.state.isFormDataSubmitted === true ? (
                <div className="m-t-20">
                  <span className="m-r-15 signin-link-disabled">
                    {IntlUtil.getText(
                      this._intl_ns_signin,
                      "content.googleLogin"
                    )}
                  </span>
                  <span className="signin-link-disabled">
                    {IntlUtil.getText(
                      this._intl_ns_signin,
                      "content.zoomLogin"
                    )}
                  </span>
                </div>
              ) : (
                <div className="m-t-20 ">
                  <span
                    className="m-r-15 signin-link"
                    onClick={() => {
                      this.setStateAsync({
                        isFormDataSubmitted: true,
                        errorMessage: null,
                      });
                      let loginDetails = {
                        loginType: "zoomInDirectLogin",
                        loginProvider: "google.com",
                      };
                      this.handlePopupSignin(loginDetails);
                    }}
                  >
                    {IntlUtil.getText(
                      this._intl_ns_signin,
                      "content.googleLogin"
                    )}
                  </span>
                  <span
                    className="signin-link"
                    onClick={() => {
                      this.setStateAsync({
                        isFormDataSubmitted: true,
                        errorMessage: null,
                      });
                      let loginDetails = {
                        loginType: "zoomDirectLogin",
                        loginProvider: "zoomLogin",
                      };
                      this.handlePopupSignin(loginDetails);
                    }}
                  >
                    {IntlUtil.getText(
                      this._intl_ns_signin,
                      "content.zoomLogin"
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="signin-progress-wrapper">
              <ProgressIndicator
                progressHidden={!this.state.isFormDataSubmitted}
                className="signin-progress-indicator"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginType: state.identityStore.loginType,
});
const mapActionToProps = {
  setIdentityProfile,
  setLoginType,
};

export default connect(mapStateToProps, mapActionToProps)(Signin);
