import {
  IDENTITY_PROFILE,
  LOGIN_TYPE
} from "./constants";

const INIT_STATE = {
  identityProfile: null,
  loginType: null
};

const identityReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case IDENTITY_PROFILE:
      return {
        ...state,
        identityProfile: action.payload,
      };
    case LOGIN_TYPE:
      return {
        ...state,
        loginType: action.payload,
      };
    default:
      return state;
  }
};

export default identityReducer;
