import api from "../../../core/helpers/api-services";
import { AppConfigProps } from "../../../core/settings/app-config";
import {
  logMessage,
  getSessionExpiredError,
} from "../../../core/actions/common-actions";
import { checkIdentitySession, checkZoomIdentitySession } from "../../../core/actions/identity-actions";
import _ from "lodash";

const actionFileName = "number-actions.js";

export function getNumbers(cancelToken) {
    return new Promise(function (resolve, reject) {
        let identitySession = checkIdentitySession();
        let zoomIdentitySession=checkZoomIdentitySession();
      if (identitySession === true &&zoomIdentitySession===true) {
        api
          .get(
            AppConfigProps.universalConnecterAPIPrefix +
              "/subscribers" +
              "/numbers",
            cancelToken
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            if (api.isCancel(err)) {
              logMessage(
                AppConfigProps.log.severityLevel.warning,
                actionFileName + ">getNumbers()",
                AppConfigProps.httpStatusCode.clientClosedRequest,
                err.message
              );
            } else {
              reject(err.response);
            }
          });
      } else {
        reject(getSessionExpiredError());
      }
    });
  }

  export function publishNumbers(
    numberObject,
    cancelToken
  ) {
    return new Promise(function (resolve, reject) {
      let identitySession = checkIdentitySession();
      let zoomIdentitySession=checkZoomIdentitySession();
      if (identitySession === true&&zoomIdentitySession===true) {
        api
          .post(
            AppConfigProps.universalConnecterAPIPrefix +
              "/subscribers" +
              "/numbers",
              numberObject,
            cancelToken
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            if (api.isCancel(err)) {
              logMessage(
                AppConfigProps.log.severityLevel.warning,
                actionFileName + ">publishNumbers()",
                AppConfigProps.httpStatusCode.clientClosedRequest,
                err.message
              );
            } else {
              reject(err.response);
            }
          });
      } else {
        reject(getSessionExpiredError());
      }
    });
  }
 
  
  export function removeNumbers(
    numberObject,
    cancelToken
  ) {
    return new Promise(function (resolve, reject) {
      let identitySession = checkIdentitySession();
      let zoomIdentitySession=checkZoomIdentitySession();
      if (identitySession === true&&zoomIdentitySession===true) {
        api
          .put(
            AppConfigProps.universalConnecterAPIPrefix +
              "/subscribers" +
              "/numbers",
              numberObject,
            cancelToken
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            if (api.isCancel(err)) {
              logMessage(
                AppConfigProps.log.severityLevel.warning,
                actionFileName + ">removeNumbers()",
                AppConfigProps.httpStatusCode.clientClosedRequest,
                err.message
              );
            } else {
              reject(err.response);
            }
          });
      } else {
        reject(getSessionExpiredError());
      }
    });
  }
  
  export function searchNumbers(list, searchKeyword) {
    return new Promise(async function (resolve, reject) {
      let identitySession = checkIdentitySession();
      let zoomIdentitySession=checkZoomIdentitySession();
      if (identitySession === true && zoomIdentitySession===true) {
        if (
          list &&
          list.length > 0 &&
          searchKeyword &&
          searchKeyword.trim().length > 0
        ) {
          let searchRecords = list.filter((customer) => {
            return (
              _.includes(
                customer.numberCountryName?.toLowerCase().trim(),
                searchKeyword?.toLowerCase().trim()
              ) ||
              _.includes(customer.phoneNumber, searchKeyword.trim()) ||
              _.includes(
                customer.publishStatus?.toLowerCase().trim(),
                searchKeyword?.toLowerCase().trim()
              ) 
            );
          });
  
          resolve(searchRecords);
        } else {
          resolve(list);
        }
      } else {
        reject(getSessionExpiredError());
      }
    });
  }