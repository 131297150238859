/**
Project: Phone Connect (c)
Title: Page Header Title
Description: Component for displaying the header title
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import React from "react";
import { SearchBox } from "@fluentui/react";

const PageHeader = ({ styleSettings, displaySettings, elementSettings,pageTitle }) => {
    return (
        <div className={`page-main-header-wrapper ${styleSettings?.wrapperClassName}`}>
            <div className="main-header-title">
                {displaySettings && displaySettings.showPageTitle === true ? (pageTitle?this.props.pageTitle:"") : null}

            </div>
            <div className="main-header-actions">
                {
                    elementSettings && displaySettings && displaySettings.showListSearch === true &&
                    (
                        <SearchBox
                            placeholder={elementSettings.listSearch?.placeholder}
                            value={elementSettings.listSearch?.searchValue}
                            onChange={(e) => elementSettings.listSearch?.onSearchChange(e)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    elementSettings.listSearch?.onKeyPress(e);
                                }
                            }}
                            onClear={async (e) => {
                                await elementSettings.listSearch?.onSearchClear(e);
                            }}
                            disabled={elementSettings.listSearch?.isSearchDisabled}
                            className={`page-search-box m-r-10 ${elementSettings.listSearch?.searchClassName}`}
                        />
                    )
                }

            </div>
        </div>
    );


}

export default PageHeader;