import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

export const reactPlugin = new ReactPlugin();

const ai = new ApplicationInsights({
  config: {
    instrumentationKey:
      process.env.REACT_APP_AZURE_INSIGHTS_INSTRUMENTATION_KEY,
    maxBatchInterval: 0,
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    extensions: [reactPlugin],
  },
});

ai.loadAppInsights();

ai.context.application.ver = process.env.REACT_APP_BUILD_VERSION;
ai.context.application.build = `Universal-Connecter ${process.env.REACT_APP_BUILD_VERSION}`;

export const appInsights = ai.appInsights;
export const AppInsightscontext = ai.context;
export const getAppInsights = () => ai;
