/**
Project: Phone Connect (c)
Title: App URLs 
Description: URLs of Application 
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
export const AppURLProps = {
  home: "/",
  signin: "/signin",
  signout: "/signout",
  authenticate: "/authenticate",
  zoomSignin: "/zoom-signin",
  zoomAuthenticate: "/zoom-authenticate",
  error: "/error",
  pageNotFound: "/404",
  sessionExpired: "/session-expired",
};
