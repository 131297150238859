/**
Project: Phone Connect (c)
Title: Main Route  
Description: Component for hadling the routes of the application
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import React, { Component } from "react";
import { createTheme, loadTheme, initializeIcons } from "@fluentui/react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import BlankLayout from "../layout/blank-layout";
import FullLayout from "../layout/full-layout";
import PrivateRoute from "./private-route";
import AzureInsightsProvider from "../core/components/azure-insights";
import ErrorBoundary from "../core/views/error-boundary";
import { HelmetProvider } from "react-helmet-async";

class MainRoute extends Component {
  constructor(props) {
    super(props);
    const appTheme = createTheme({
      palette: {
        themePrimary: "#0091de",
        themeLighterAlt: "#f4fafe",
        themeLighter: "#d2ecfa",
        themeLight: "#acdbf5",
        themeTertiary: "#5ebaeb",
        themeSecondary: "#1b9ce2",
        themeDarkAlt: "#0082c8",
        themeDark: "#006ea9",
        themeDarker: "#00517c",
        neutralLighterAlt: "#f8f8f8",
        neutralLighter: "#f4f4f4",
        neutralLight: "#eaeaea",
        neutralQuaternaryAlt: "#dadada",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c8c8",
        neutralTertiary: "#595959",
        neutralSecondary: "#373737",
        neutralPrimaryAlt: "#2f2f2f",
        neutralPrimary: "#000000",
        neutralDark: "#151515",
        black: "#0b0b0b",
        white: "#ffffff",
      },
    });
    loadTheme(appTheme);
    initializeIcons();
  }

  render() {
    return (
      <BrowserRouter basename="/">
        <AzureInsightsProvider>
          <HelmetProvider>
            <ErrorBoundary>
              <Switch>
                <Route path="/signin" component={BlankLayout} />
                <Route path="/authenticate" component={BlankLayout} />
                <Route path="/zoom-signin" component={BlankLayout} />
                <Route path="/zoom-authenticate" component={BlankLayout} />
                <Route path="/signout" component={BlankLayout} />
                <Route path="/error" component={BlankLayout} />
                <Route path="/404" component={BlankLayout} />
                <Route path="/page-not-found" component={BlankLayout} />
                <Route path="/session-expired" component={BlankLayout} />
                <PrivateRoute path="/" component={FullLayout} />
              </Switch>
            </ErrorBoundary>
          </HelmetProvider>
        </AzureInsightsProvider>
      </BrowserRouter>
    );
  }
}

export default MainRoute;
