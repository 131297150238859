/**
Project: Phone Connect (c)
Title: Page Util 
Description: Helper class with functions for Page 
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import IntlUtil from "./intl-util";
class PageUtil {
  // Scroll page to top
  static scrollToTop(currProps = null, prevProps = null) {
    if (currProps && prevProps) {
      if (currProps.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }

  // Scroll page to top
  static scrollIntoView(objRef) {
    if (objRef && objRef.current) {
      //objRef.current.scrollIntoView({ behavior: "smooth" });
      objRef.current.scrollIntoView();
    }
  }

  static getHeadTitle(title = null) {
    if (title) {
      return `${IntlUtil.getText("common", "title.appName")} | ${title}`;
    } else {
      return IntlUtil.getText("common", "title.appName");
    }
  }

  //returns page url
  static getPageURL = (location) => {
    let pageURL = "";
    if (location) {
      pageURL = location.pathname;
      if (location.search && location.search.length > 0)
        pageURL = pageURL + location.search;
      if (location.hash && location.hash.length > 0)
        pageURL = pageURL + location.hash;
    }
    return pageURL;
  };

  // Parse Query params string and return query params object
  static getQueryParams(locationSearch) {
    if (locationSearch && locationSearch.trim().length > 1) {
      let queryString = locationSearch.substring(1, locationSearch.length);
      if (queryString && queryString.trim().length > 0) {
        let stringArr = queryString.split("&");
        if (stringArr && stringArr.length > 0) {
          let queryParams = {};
          for (let i = 0; i < stringArr.length; i++) {
            let paramData = stringArr[i].split("=");
            if (paramData && paramData.length === 2) {
              queryParams[paramData[0]] = paramData[1];
            }
          }
          return queryParams;
        }
      }
    }
    return null;
  }

  // Create a CSV file for download
  static downloadCSVFile(csvFileName, csvFileData) {
    if (window.navigator.msSaveBlob) {
      // IE 10 and later, and Edge.
      let blobObject = new Blob([csvFileData], { type: "text/csv" });
      window.navigator.msSaveBlob(blobObject, csvFileName);
    } else {
      // Everthing else (except old IE).
      // Create a dummy anchor (with a download attribute) to click.
      let anchor = document.createElement("a");
      anchor.download = csvFileName;
      if (window.URL.createObjectURL) {
        // Everything else new.
        let blobObject = new Blob([csvFileData], { type: "text/csv" });
        anchor.href = window.URL.createObjectURL(blobObject);
      } else {
        // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
        // Load up the data into the URI for "download."
        anchor.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(csvFileData);
      }
      // Now, click it.
      if (document.createEvent) {
        let event = document.createEvent("MouseEvents");
        event.initEvent("click", true, true);
        anchor.dispatchEvent(event);
      } else {
        anchor.click();
      }
    }
  }
}

export default PageUtil;
