/**
Project: Phone Connect (c)
Title: Store
Description: Redux store for maintaining the state
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import reducers from "./reducers";

const logger = createLogger();

export function configureStore() {
  let middleware = [thunk];

  if (process.env.REACT_APP_ENV !== "production") {
    middleware = [thunk, logger];
  }

  const store = createStore(reducers, applyMiddleware(...middleware));

  return store;
}
