export const UniversalConnecterConstants = {
  APP_NAME: "Phone Connect",
  ZOOM_URL: "https://zoom.us",
  TABLE_DATA: {
    SORT_ORDER: {
      ASCENDING: "ASC",
      DESCENDING: "DESC",
    },
    PAGE_LIMIT_DEFAULT: 25,
    PAGE_OFFSET_DEFAULT: 0,
    PAGE_LIMIT_OPTIONS: [10, 25, 50, 100],
  },
  IDENTITY: {
    SESSION_TIMEOUT_SECONDS: process.env.REACT_APP_SESSION_TIMEOUT_SECONDS, // 30 Minutes (1800 Secs) for session continue or not
    IDLE_TIMEOUT_SECONDS: process.env.REACT_APP_IDLE_TIMEOUT_SECONDS, // 5 Minutes (300 Secs) for inactive or idle timeout
  },
  FORM_SUBMIT_STATUS: {
    SUCCESS: "S",
    FAILURE: "F",
    INFO: "I",
  },

  NUMBER: {
    PUBLISH_STATUS: {
      ALL: "All",
      PUBLISHED: "Published",
      UNPUBLISHED: "Unpublished",
      NOT_PUBLISHED: "NotPublished",
    },
    NUMBER_STATUS: {
      STAGED: "Staged",
    },
    MAXIMUM_PHONE_NUMBER_ARRAY_LENGTH: 200,
  },
};
