/**
Project: Phone Connect (c)
Title: Token Util 
Description: Helper class with functions for Token 
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import { jwtDecode } from "jwt-decode";
import { AppConfigProps } from "../settings/app-config";

class TokenUtil {
  // Set token to local storage
  static setIdentityToken(sippioAccessToken, sippioRefreshToken) {
    let token = {
      accessToken: sippioAccessToken,
      refreshToken: sippioRefreshToken,
    };
    if (token) {
      localStorage.setItem(
        AppConfigProps.identitySession.universalConnecterTokenKey,
        JSON.stringify(token)
      );
    }
  }

  // Remove token from  local storage
  static removeIdentityToken() {
    localStorage.removeItem(
      AppConfigProps.identitySession.universalConnecterTokenKey
    );
    localStorage.clear();
  }

  // Get access token from local storage
  static getIdentitySippioAccessToken() {
    if (
      localStorage.getItem(AppConfigProps.identitySession.universalConnecterTokenKey)
    ) {
      const token = localStorage.getItem(
        AppConfigProps.identitySession.universalConnecterTokenKey
      );
      if (token) {
        const parsedToken = JSON.parse(token);
        if (parsedToken && parsedToken.accessToken)
          return parsedToken.accessToken;
      }
    }
    return null;
  }

  // Get refresh token from local storage
  static getIdentitySippioRefreshToken() {
    if (
      localStorage.getItem(AppConfigProps.identitySession.universalConnecterTokenKey)
    ) {
      const token = localStorage.getItem(
        AppConfigProps.identitySession.universalConnecterTokenKey
      );
      if (token) {
        const parsedToken = JSON.parse(token);
        if (parsedToken && parsedToken.refreshToken)
          return parsedToken.refreshToken;
      }
    }
    return null;
  }

  // Get access token from local storage and parse the data
  static getIdentitySippioAccessTokenData() {
    const token = JSON.parse(
      localStorage.getItem(AppConfigProps.identitySession.universalConnecterTokenKey)
    );
    if (token && token.accessToken) {
      const decodedToken = jwtDecode(token.accessToken);
      if (decodedToken && decodedToken.user) {
        const identityData = {
          firstName: decodedToken?.user?.firstName,
          lastName: decodedToken?.user?.lastName,
          userName: (
            decodedToken?.user?.firstName +
            " " +
            decodedToken?.user?.lastName
          ).trim(),
          userEmail: decodedToken?.user?.email,
          accountNumber: decodedToken?.user?.accountNumber,
          accountName: decodedToken?.user?.accountName,
          zoomEmail: decodedToken?.zoomAccount?.email ?? null,
          tokenExpiry: decodedToken?.exp,
        };

        return identityData;
      }
    }
    return null;
  }
  static getIdentitySippioZoomAccessTokenData() {
    const token = JSON.parse(
      localStorage.getItem(AppConfigProps.identitySession.universalConnecterTokenKey)
    );
    if (token && token.accessToken) {
      const decodedToken = jwtDecode(token.accessToken);
      if (decodedToken && decodedToken.zoomAccessToken) {
        const identityData = {
          firstName: decodedToken?.zoomAccount?.firstName,
          lastName: decodedToken?.zoomAccount?.lastName,
          userName: (
            decodedToken?.zoomAccount?.firstName +
            " " +
            decodedToken?.zoomAccount?.lastName
          ).trim(),
          zoomAccountEmail: decodedToken?.zoomAccount?.email,
          zoomTokenExpiry: decodedToken?.zoomAccount?.expiryTime,
        };

        return identityData;
      } else {
        return null;
      }
    }
    return null;
  }
}



export default TokenUtil;
