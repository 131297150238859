import {
    Modal,
    IconButton,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
    StickyPositionType,
    Sticky,
    DetailsList,
    DetailsListLayoutMode,
    ConstrainMode,
    TooltipHost,
    TooltipOverflowMode,
} from "@fluentui/react";
import IntlUtil from "../../../core/helpers/intl-util";

const NumberUpdateStatusModal = ({
    intlNamespace,
    isModalOpen,
    isModalClose,
    updatedNumberRecords,
    tableHeaderColumns,
}) => {
    const numbersRecord = updatedNumberRecords;
    const handleItemRender = (item, index, column) => {
        switch (column.key) {
            case "phoneNumber":
                return <div>{`+${item.phoneNumber}`}</div>;
            default:
                return (
                    <div>
                        <TooltipHost
                            overflowMode={TooltipOverflowMode.Self}
                            hostClassName="invoice-description-text"
                            content={item[column.fieldName]}
                        >
                            {item[column.fieldName]}
                        </TooltipHost>
                    </div>
                );
        }
    };

    return (
        <Modal isOpen={isModalOpen} className="number-update-status-modal">
            <div className="modal-wrapper">
                <div className="modal-header-wrapper">
                    <div className="modal-header-text">
                        {IntlUtil.getText(intlNamespace, "content.zoomNumbers")}
                    </div>
                    <IconButton
                        iconProps={{ iconName: "Cancel" }}
                        ariaLabel="Close popup modal"
                        onClick={() => isModalClose()}
                    />
                </div>
                <div className="modal-table-content">
                    {numbersRecord && numbersRecord.length > 0 ? (
                        <div className="modal-table-list">
                            <div className="modal-table">
                                <ScrollablePane
                                    scrollbarVisibility={ScrollbarVisibility.always}
                                >
                                    <DetailsList
                                        columns={tableHeaderColumns}
                                        items={numbersRecord}
                                        compact={false}
                                        selectionMode={SelectionMode.none}
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                        onRenderItemColumn={handleItemRender}
                                        onRenderDetailsHeader={(
                                            detailsHeaderProps,
                                            defaultRender
                                        ) => (
                                            <Sticky
                                                stickyPosition={StickyPositionType.Header}
                                                isScrollSynced
                                                stickyClassName="modal-table-header-sticky"
                                            >
                                                {defaultRender(detailsHeaderProps)}
                                            </Sticky>
                                        )}
                                        constrainMode={ConstrainMode.unconstrained}
                                    />
                                </ScrollablePane>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </Modal>
    );
};

export default NumberUpdateStatusModal;
