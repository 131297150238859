/**
Project: Phone Connect (c)
Title: Reducers
Description: Combine all reducers of the application 
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import { combineReducers } from "redux";
import appReducer from "./app-reducer";
import identityReducer from "./identity-reducer"

const reducers = combineReducers({
  appSettings: appReducer,
  identityStore: identityReducer
});

export default reducers;
