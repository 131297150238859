/**
Project: Phone Connect (c)
Title: Telemetry Util 
Description: Helper class with functions for Page 
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/

import { appInsights } from "../settings/azure-insights-config";
import { AppConfigProps } from "../settings/app-config";

class TelemetryUtil {
  static logException(exception, properties) {
    appInsights.trackException(exception, properties);
  }

  static logInformation(message, properties) {
    appInsights.trackTrace({
      message: message,
      severityLevel: AppConfigProps.SeverityLevel.Information,
      properties: properties,
    });
  }

  static logWarning(message, properties) {
    appInsights.trackTrace({
      message: message,
      severityLevel: AppConfigProps.SeverityLevel.Warning,
      properties: properties,
    });
  }

  static logError(message, properties) {
    appInsights.trackTrace({
      message: message,
      severityLevel: AppConfigProps.SeverityLevel.Error,
      properties: properties,
    });
  }
}

export default TelemetryUtil;
