/**
Project: Phone Connect (c)
Title: Navbar
Description: Component for displaying the navigation navbar of the application
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  FontIcon,
  Link,
  Callout,
  Persona,
  Image,
  TooltipHost,
  TooltipOverflowMode,
} from "@fluentui/react";
import IntlUtil from "../../../core/helpers/intl-util";
import TokenUtil from "../../../core/helpers/token-util";
import { getAppInsights } from "../../../core/settings/azure-insights-config";
import { setIdentityProfile } from "../../../core/actions/identity-actions";
import { AppURLProps } from "../../../core/settings/app-urls";
import ZoomIcon from "../../../../assets/images/zoom-icon.png";
import { UniversalConnecterConstants } from "../settings/universal-connecter-constants";

// linkText: {
//   color: 'black',
//   selectors: { '&:hover': { color: 'blue' } }
// },
// compositeLink: {
//   selectors: {
//     '&:active ,&:focus-within': { backgroundColor: 'orange' }
//   }
// },
// navItem: {
//   selectors: { '&:hover': { backgroundColor: 'blue' } }
// }

class Navbar extends Component {
  _isMounted = false;
  _intl_ns_common = "common";
  _intl_ns_suc_navbar = "suc_navbar";
  _azureInsights = getAppInsights();

  zoomQuerySelector = document.querySelector("#avatar");
  profileQuerySelector = document.querySelector("#zoom");

  constructor(props) {
    super(props);
    this.state = {
      isMobilePanelOpen: false,
      navLinkGroups: [],
      navbarFlag: false,
      tokenData: null,
      personaText: "",
      isTooltipDisplayed: false,
      isZoomTooltipDisplayed: false,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    await this.loadPageData();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.isTooltipDisplayed !== this.state.isTooltipDisplayed) {
      document.addEventListener("mousedown", (event) => {
        if (this.profileQuerySelector?.contains(event.target)) {
          this.setStateAsync({ isTooltipDisplayed: true });
        } else {
          this.setStateAsync({ isTooltipDisplayed: false });
        }
      });
    }
    if (
      prevState.isZoomTooltipDisplayed !== this.state.isZoomTooltipDisplayed
    ) {
      document.addEventListener("mousedown", (event) => {
        if (this.zoomQuerySelector?.contains(event.target)) {
          this.setStateAsync({ isZoomTooltipDisplayed: true });
          this.setStateAsync({ isTooltipDisplayed: false });
        } else {
          this.setStateAsync({ isZoomTooltipDisplayed: false });
        }
      });
    }
  }

  async componentWillUnmount() {
    this._isMounted = false;
  }

  loadPageData = async () => {
    let tokenData = TokenUtil.getIdentitySippioAccessTokenData();
    let lastName = tokenData?.lastName ? tokenData?.lastName : "";
    let personaText = { text: tokenData?.firstName + " " + lastName };
    await this.setStateAsync({
      tokenData: tokenData,
      personaText: personaText,
    });
  };

  handleZoomRedirect = async () => {
    window.open(UniversalConnecterConstants.ZOOM_URL, "_blank");
  };
  signout = async (e) => {
    //access log signout
    if (this._azureInsights)
      await this._azureInsights.clearAuthenticatedUserContext();
    this.props.history.push(AppURLProps.signout);
    return;
  };

  render() {
    return (
      <>
        <div id="suc-navbar-wrapper">
          <div className="navbar-brand-wrapper">
            <div className="navbar-brand">
              {IntlUtil.getText(this._intl_ns_common, "title.appName")}
            </div>
            <div className="navbar-content-wrapper">
              {this.state?.tokenData?.zoomEmail ? (
                <>
                  <span
                    onClick={this.handleZoomRedirect}
                    className="navbar-zoom-content"
                  >
                    {this.state?.tokenData?.zoomEmail ? (
                      <>
                        {IntlUtil.getText(
                          this._intl_ns_suc_navbar,
                          "content.zoomAccount"
                        )}{" "}
                        <TooltipHost
                          hostClassName="m-0 p-0"
                          content={IntlUtil.getText(
                            this._intl_ns_suc_navbar,
                            "content.goToZoomAccount"
                          )}
                        >
                          <div className="text-fw-semibold">
                            {this.state?.tokenData?.zoomEmail}
                          </div>
                        </TooltipHost>
                      </>
                    ) : null}
                  </span>
                </>
              ) : null}
              <span id="zoom" className="navbar-image-wrapper">
                {this.state?.tokenData?.zoomEmail ? (
                  <>
                    <Image
                      src={ZoomIcon}
                      onClick={() =>
                        this.setStateAsync({ isZoomTooltipDisplayed: true })
                      }
                    />
                  </>
                ) : null}
              </span>
              <div className="navbar-logo-wrapper">
                <FontIcon
                  id="avatar"
                  onClick={() =>
                    this.setStateAsync({ isTooltipDisplayed: true })
                  }
                  iconName="Contact"
                  className="navbar-identity-avatar cursor-pointer"
                />
              </div>
            </div>
          </div>
          {this.state.isTooltipDisplayed === true ? (
            <Callout
              target={".navbar-logo-wrapper"}
              className="navbar-callout-wrapper"
              onFocus={() => this.setStateAsync({ isTooltipDisplayed: true })}
            >
              <div className="navbar-content-wrapper">
                <div>
                  <Persona
                    hidePersonaDetails={true}
                    className="m-t-15 m-r-10"
                    {...this.state.personaText}
                  />
                </div>

                <div>
                  <div>
                    <TooltipHost
                      hostClassName="user-description-text"
                      content={this.state.personaText?.text}
                      overflowMode={TooltipOverflowMode.Self}
                    >
                      <span className="text-fw-semibold">{this.state.personaText?.text}</span>
                    </TooltipHost>
                  </div>

                  <div>
                    <TooltipHost
                      hostClassName="user-description-text"
                      content={this.state.tokenData?.userEmail}
                      overflowMode={TooltipOverflowMode.Self}
                    >
                      <span className="text-fs-regular zoom-user-account-text">
                        {this.state.tokenData?.userEmail}
                      </span>
                    </TooltipHost>
                  </div>
                  <div>
                    <span className="text-fs-regular zoom-user-account-text">
                      {this.state.tokenData?.accountNumber}
                    </span>
                  </div>

                  <div className="navbar-identity-link">
                    <Link
                      className="text-fs-regular"
                      onClick={(e) => this.signout(e)}
                    >
                      {IntlUtil.getText(
                        this._intl_ns_suc_navbar,
                        "content.signout"
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            </Callout>
          ) : null}
          {this.state.isZoomTooltipDisplayed === true ? (
            <Callout
              target={`#zoom`}
              className="navbar-zoom-callout-wrapper"
              onClick={() =>
                this.setStateAsync({ isZoomTooltipDisplayed: true })
              }
            >
              <div className="navbar-zoom-content-wrapper">
                <span
                  onClick={this.handleZoomRedirect}
                  className="text-fs-regular zoom-user-account-text"
                >
                  {this.state.tokenData?.zoomEmail}
                </span>
              </div>
            </Callout>
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  identityProfile: state.identityStore.identityProfile,
});
const mapActionToProps = { setIdentityProfile };

export default connect(mapStateToProps, mapActionToProps)(Navbar);
