/**
Project: Phone Connect (c)
Title: List Util
Description: Helper class for list page functionalities
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import { UniversalConnecterConstants } from "../settings/universal-connecter-constants";
class ListUtil {
  // Sort the list records
  static sortListRecords(list, sortColumn, sortOrder, sortType) {
    return new Promise(async function (resolve, reject) {
      if (sortType === null || sortType === "") sortType = "string";
      if (list && list.length > 0) {
        let sortRecords = list.sort((firstItem, secondItem) => {
          if (sortType === "string") {
            firstItem[sortColumn] = firstItem[sortColumn]
              ? firstItem[sortColumn]
              : "";
            secondItem[sortColumn] = secondItem[sortColumn]
              ? secondItem[sortColumn]
              : "";
          }
          if (sortType === "number") {
            if (
              sortOrder ===
              UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.ASCENDING
            ) {
              return firstItem[sortColumn] - secondItem[sortColumn];
            } else {
              return secondItem[sortColumn] - firstItem[sortColumn];
            }
          } else if (sortType === "string") {
            if (
              sortOrder ===
              UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.ASCENDING
            ) {
              return firstItem[sortColumn].localeCompare(
                secondItem[sortColumn],
                "en",
                {
                  numeric: true,
                }
              );
            } else {
              return secondItem[sortColumn].localeCompare(
                firstItem[sortColumn],
                "en",
                {
                  numeric: true,
                }
              );
            }
          } else {
            return null;
          }
        });
        resolve(sortRecords);
      } else {
        resolve(list);
      }
    });
  }

  // Slice the list records
  static sliceListRecords(list, pageOffset, pageLimit) {
    return new Promise(async function (resolve, reject) {
      if (list && list.length > 0 && pageOffset >= 0 && pageLimit > 0) {
        let start = pageOffset * pageLimit;
        let end = start + pageLimit;
        if (end > list.length) end = list.length;

        let sliceRecords = await list.slice(start, end);
        resolve(sliceRecords);
      } else {
        resolve(list);
      }
    });
  }
}

export default ListUtil;
