import React, { Component } from "react";
import { Dialog } from "@fluentui/react";

class PageOverlay extends Component {
  render() {
    return (
      <>
        <Dialog
          hidden={this.props.hidden}
          modalProps={{
            isDarkOverlay: false,
            styles: {
              root: { opacity: 0.5 },
              main: { opacity: 0 },
            },
          }}
        ></Dialog>
      </>
    );
  }
}
export default PageOverlay;
