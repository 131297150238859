/**
Project: Phone Connect (c)
Title: Full Layout  
Description: Component for hadling the routes of logged-in user
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import {
  Text,
  Dialog,
  PrimaryButton,
  DefaultButton,
  DialogFooter,
  DialogType,
} from "@fluentui/react";

import { connect } from "react-redux";
import api from "../core/helpers/api-services";
import { PublicClientApplication } from "@azure/msal-browser";
import IntlUtil from "../core/helpers/intl-util";
import PageUtil from "../core/helpers/page-util";
import TokenUtil from "../core/helpers/token-util";
import PageLoader from "../pages/common/helpers/page-loader";
import { manageError } from "../core/actions/common-actions";
import { msalConfig } from "../core/settings/msal-config";
import {
  checkIdentitySession,
  renewIdentitySession,
} from "../core/actions/identity-actions";
import { getAppInsights } from "../core/settings/azure-insights-config";
import { UniversalConnecterConstants } from "../pages/common/settings/universal-connecter-constants";
import IdleTimer from "../core/helpers/idle-timer";
import { AppRoutes } from "../routes/app-routes";
import SessionTimer from "../core/helpers/session-timer";
import Navbar from "../pages/common/components/navbar";
import ErrorPageNotFound from "../core/views/error-page-not-found";
import { AppPageTitle } from "../core/components/app-page-title";
import { AppURLProps } from "../core/settings/app-urls";

class FullLayout extends Component {
  _isMounted = false;
  _axiosSource = api.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _intl_ns_common = "common";
  _intl_ns_suc_common = "suc_common";
  _azureInsights = getAppInsights();
  _identityIdleTimer = null;
  _identitySessionTimer = null;

  constructor(props) {
    super(props);
    this.state = {
      isAppDataFetched: false,
      isSessionTimeoutModalOpen: false,
      appRoutes: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtil.scrollToTop();
    await this.loadPageData();

    this._identityIdleTimer = new IdleTimer({
      timeout: UniversalConnecterConstants.IDENTITY.IDLE_TIMEOUT_SECONDS, //time in seconds
      onTimeout: () => this.signout(),
    });

    this._identitySessionTimer = new SessionTimer({
      timeout: UniversalConnecterConstants.IDENTITY.SESSION_TIMEOUT_SECONDS, // time in seconds
      onTimeout: () => this.openSessionTimeoutModal(),
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      IntlUtil.getText(
        this._intl_ns_common,
        "notification.warning.requestCancelled"
      )
    );
    if (this._identityIdleTimer) this._identityIdleTimer.cleanUp();
    if (this._identitySessionTimer) this._identitySessionTimer.cleanUp();
  }

  openSessionTimeoutModal = async () =>
    await this.setStateAsync({ isSessionTimeoutModalOpen: true });

  closeSessionTimeoutModal = async () =>
    await this.setStateAsync({ isSessionTimeoutModalOpen: false });

  updateSessionTimeoutStatus = (status) => {
    this.closeSessionTimeoutModal();
    if (status) {
      this._identitySessionTimer.startInterval();
      let isSessionValid = checkIdentitySession();
      if (isSessionValid === true) {
        renewIdentitySession(this._cancelToken);
      } else {
        this.props.history.push(AppURLProps.signin);
      }
    } else {
      this.signout();
    }
  };

  signout = async () => {
    let loginDetails = JSON.parse(localStorage.getItem("loginDetailsHint"));
    if (loginDetails?.loginType === "zoomInDirectLogin") {
      let msalContext = new PublicClientApplication(msalConfig);
      let account = msalContext.getAccountByUsername(
        TokenUtil.getIdentitySippioAccessTokenData()?.userEmail
      );
      if (this._azureInsights)
        await this._azureInsights.clearAuthenticatedUserContext();
      TokenUtil.removeIdentityToken();
      localStorage.clear();
      await msalContext.logoutRedirect({ account });
      return;
    } else {
      TokenUtil.removeIdentityToken();
      localStorage.clear();
      this.props.history.push(AppURLProps.signin);
    }
  };

  loadPageData = async () => {
    try {
      await this.setStateAsync({ isAppDataFetched: false });
      await this.setStateAsync({ appRoutes: AppRoutes });
      await this.setStateAsync({ isAppDataFetched: true });
    } catch (err) {
      await this.setStateAsync({ isAppDataFetched: true });
      await manageError(err, this.props.history);
    }
  };

  render() {
    return (
      <div id="suc-layout-wrapper">
        <AppPageTitle pageTitle="" />
        <PageLoader
          status={this.state.isAppDataFetched}
          size="medium"
          labelPosition="bottom"
          label={IntlUtil.getText(
            this._intl_ns_common,
            "content.loadingInprogress"
          )}
          type="full"
        >
          <>
            <Navbar {...this.props} />
            <div id="suc-main-wrapper">
              <div id="suc-main-content">
                <Switch>
                  {this.state.appRoutes && this.state.appRoutes.length > 0
                    ? this.state.appRoutes.map((route1, index1) => {
                      return (
                        <Route
                          path={route1.url}
                          component={route1.component}
                          key={`R1-${index1}`}
                          exact={route1.exactPath}
                        />
                      );
                    })
                    : null}
                  <Route path="*" component={ErrorPageNotFound} key={`EPNF`} />
                </Switch>
              </div>
              <div id="suc-main-footer"></div>
            </div>

            <Dialog
              hidden={!this.state.isSessionTimeoutModalOpen}
              //onDismiss={this.closeSessionTimeoutModal}
              dialogContentProps={{
                type: DialogType.normal,
                showCloseButton: false,
                title: IntlUtil.getText(
                  this._intl_ns_suc_common,
                  "notification.warning.sessionTimeoutTitle"
                ),
                subText: (
                  <>
                    <Text>
                      {IntlUtil.getText(
                        this._intl_ns_suc_common,
                        "notification.warning.sessionTimeoutMessage1"
                      )}
                    </Text>
                    <Text>
                      {IntlUtil.getText(
                        this._intl_ns_suc_common,
                        "notification.warning.sessionTimeoutMessage2"
                      )}
                    </Text>
                  </>
                ),
              }}
            /*
      modalProps={{
        isBlocking: true,
        styles: { main: { minWidth: "375px" } },
      }}
      styles={{ dialogDefaultMinWidth: "375px" }}
      */
            >
              <DialogFooter>
                <PrimaryButton
                  onClick={() => this.updateSessionTimeoutStatus(true)}
                  text={IntlUtil.getText(
                    this._intl_ns_suc_common,
                    "content.yes"
                  )}
                  className="page-button-primary"
                />
                <DefaultButton
                  onClick={() => this.updateSessionTimeoutStatus(false)}
                  text={IntlUtil.getText(
                    this._intl_ns_suc_common,
                    "content.no"
                  )}
                  className="page-button-default"
                />
              </DialogFooter>
            </Dialog>
          </>
        </PageLoader>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  appName: state.appSettings.appName,
});
const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(FullLayout);
