/**
Project: Phone Connect (c)
Title: App Config 
Description: Config file for Application 
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
export const AppConfigProps = {
  universalConnecterAPIPrefix: "/universal-connecter",
  httpStatusCode: {
    ok: 200,
    badRequest: 400,
    unauthorized: 401,
    forbidden: 403,
    notFound: 404,
    methodNotAllowed: 405,
    unprocessable: 422,
    requestTimeout: 408,
    clientClosedRequest: 499,
    internalServerError: 500,
  },
  identitySession: {
    universalConnecterTokenKey: "universal-connecter-token",
    universalConnecterIdleTimerKey: "universal-connecter-idle-timer",
    universalConnecterMicrosoftTokenKey: "universal-connecter-microsoft-token",
    universalConnecterSessionTimerKey: "universal-connecter-session-timer",
    expiryCheckRemainingSeconds: 1200, // 20 mins
  },
  log: {
    messages: true,
    severityLevel: {
      info: "INFO",
      warning: "WARANING",
      error: "ERROR",
      fatal: "FATAL",
    },
  },
};
