/**
Project: Phone Connect (c)
Title: Number Table Columns  
Description: Settings related to Number list page table column 
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/

import IntlUtil from "../../../core/helpers/intl-util";
import { Text } from "@fluentui/react";
import { UniversalConnecterConstants } from "../../common/settings/universal-connecter-constants";
//import { InfoIcon } from "@fluentui/react-icons";

export const NumberTableColumns = (intlNamespace) => {
  return [
    {
        key: "phoneNumber",
        name: (
          <div>
            <Text className="m-r-5">
              {IntlUtil.getText(intlNamespace, "content.phoneNumber")}
            </Text>
          </div>
        ),
        fieldName: "phoneNumber",
        data: "string",
        minWidth: 120,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSortable: true,
        isSorted: true,
        isSortedDescending: false,
      },
    {
      key: "numberCountryName",
      name: (
        <div>
          <Text className="m-r-5">
            {IntlUtil.getText(intlNamespace, "content.country")}
          </Text>
        </div>
      ),
      fieldName: "numberCountryName",
      data: "string",
      minWidth: 200,
      maxWidth: 220,
      isRowHeader: true,
      isResizable: true,
      isSortable: true,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: "publishStatus",
      name: IntlUtil.getText(intlNamespace, "content.publishStatus"),
      fieldName: "publishStatus",
      data: "string",
      minWidth: 50,
      maxWidth: 70,
      isRowHeader: true,
      isResizable: true,
      isSortable: true,
      isSorted: false,
      isSortedDescending: false,
    },
  ];
};

export const NumberPublishStatusTabs = (intlNamespace) => {
  return [
    {
      tabName: IntlUtil.getText(intlNamespace, "content.all"),
      tabValue: UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.ALL,
    },
    {
      tabName: IntlUtil.getText(intlNamespace, "content.published"),
      tabValue: UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED,
    },
    {
      tabName: IntlUtil.getText(intlNamespace, "content.notPublished"),
      tabValue: UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED,
    },
  ];
};

