import React,{Component} from "react";
import { UniversalConnecterURLProps } from "../settings/universal-connecter-urls";

class Home extends Component{
      async componentDidMount() {
        this._isMounted = true;
        this.props.history.push(UniversalConnecterURLProps.numberManagement.numberList)

}

render(){
    return(<></>)
}
}

export default Home;