/**
Project: Phone Connect (c)
Title: Common Actions 
Description: Common actions for all components
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import { AppURLProps } from "../settings/app-urls";
import { AppConfigProps } from "../settings/app-config";

// Manage Errors
// Manage Errors
export function manageError(err, history) {
  return new Promise(function (resolve, reject) {
    console.error(err);
    let path = "";
    if (history.location.pathname && history.location.pathname.length > 0)
      path = path + history.location.pathname;
    if (history.location.search && history.location.search.length > 0)
      path = path + history.location.search;
    if (history.location.hash && history.location.hash.length > 0)
      path = path + history.location.hash;
    let statusCode = "";
    if (err && err.status) statusCode = err.status;
    let message = "";
    if (err && err.data && err.data.message) message = err.data.message;
    let endPoint = "";
    if (err && err.data && err.config && err.config.baseURL && err.config.url)
      endPoint = err.config.baseURL + err.config.url;
    let method = "";
    if (err && err.data && err.config && err.config.method)
      method = err.config.method;
    // let errorMessage = "";
    // if (err && err.data && err.data.errors && err.data.errors.length > 0)
    //   errorMessage = err.data.errors[0]?.message;

    logMessage(
      AppConfigProps.log.severityLevel.error,
      path,
      statusCode,
      message
    );
    if (
      err &&
      (err.status === AppConfigProps.httpStatusCode.notFound ||
        err.status === AppConfigProps.httpStatusCode.unauthorized)
    ) {
      history.push({
        pathname: AppURLProps.pageNotFound,
        state: {
          pageURL: path,
          method: method,
          statusCode: statusCode,
          errMessage: message,
          endPoint: endPoint,
        },
      });
    } else if (
      err &&
      err.status === AppConfigProps.httpStatusCode.requestTimeout
    ) {
      history.push(AppURLProps.signin);
    } else {
      history.push({
        pathname: AppURLProps.error,
        state: {
          pageURL: path,
          method: method,
          statusCode: statusCode,
          errMessage: message,
          endPoint: endPoint,
        },
      });
    }
  });
}

// Log error message
export function logMessage(type, path, statusCode, message) {
  if (AppConfigProps.log.messages === true) {
    console.log(type, path, statusCode, message);
  }
}

//Get Session Expired Error Message
export function getSessionExpiredError() {
  return {
    status: AppConfigProps.httpStatusCode.requestTimeout,
    message: "Session expired please Signin again.",
  };
}