/**
Project: Phone Connect (c)
Title: App
Description: App Component is the main component of the application which acts as a container for all other components
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import React, { Component } from "react";
import { Provider } from "react-redux";
import { configureStore } from "./app/store/store";
import MainRoute from "./app/routes/main-route";
import RequestUtil from "./app/core/helpers/request-util";

class App extends Component {
  render() {
    RequestUtil.setRequestHeaders();
    return (
      <Provider store={configureStore()}>
        <MainRoute />
      </Provider>
    );
  }
}

export default App;
