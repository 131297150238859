/**
Project: Phone Connect (c)
Title: Response Util 
Description: Helper class with functions for Response 
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
class ResponseUtil {
  // Get the response statusc code
  static getStatus(res) {
    if (res && res.status) {
      return res.status;
    } else return null;
  }
  // Get the response main message
  static getMessage(res) {
    if (res && res.message) {
      return res.message;
    } else return null;
  }
  // Get the errors from error response
  static getErrors(err) {
    if (err && err.data && err.data.errors && err.data.errors.length > 0) {
      let errors = [];
      err.data.errors.forEach((error) => {
        const obj = {
          code: error.code,
          message: error.message,
        };
        errors.push(obj);
      });
      return errors;
    } else return null;
  }

  // Get the first error from error response
  static getPrimeError(err) {
    if (err && err.data && err.data.errors && err.data.errors.length > 0) {
      const obj = {
        code: err.data.errors[0].code,
        message: err.data.errors[0].message,
      };
      return obj;
    } else return null;
  }
}
export default ResponseUtil;
