import React, { Component } from "react";
import { Image, PrimaryButton } from "@fluentui/react";
import ZoomLogo from "../../../assets/images/zoom-logo.png";
import api from "../helpers/api-services";
import { AppPageTitle } from "./app-page-title";
import IntlUtil from "../helpers/intl-util";
import PageUtil from "../helpers/page-util";
import PageHeader from "../../pages/common/helpers/page-header";
import Navbar from "../../pages/common/components/navbar";
import { checkIdentitySession } from "../actions/identity-actions";
import { AppURLProps } from "../settings/app-urls";

class ZoomSignin extends Component {
  _intl_ns_suc_home = "signin";
  _intl_ns_common = "suc_common";

  _isMounted = false;
  _axiosSource = api.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
    };
  }
  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };
  async componentDidMount() {
    this._isMounted = true;
    PageUtil.scrollToTop();
    let isSessisonValid = false;
    isSessisonValid = checkIdentitySession();
    if (isSessisonValid === false) {
      return this.props.history.push(AppURLProps.signin);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      IntlUtil.getText(
        this._intl_ns_common,
        "notification.warning.requestCancelled"
      )
    );
  }

  loadZoomSigninPage = () => {
    let isSessisonValid = false;
    isSessisonValid = checkIdentitySession();
    if (isSessisonValid === true) {
      const clientId = process.env.REACT_APP_ZOOM_CLIENT_ID;
      const thisUrl = new URL(process.env.REACT_APP_ZOOM_AUTH_URL);
      thisUrl.searchParams.set("response_type", "code");
      thisUrl.searchParams.set("client_id", clientId);
      thisUrl.searchParams.set(
        "redirect_uri",
        window.location.origin + "/zoom-authenticate"
      );
      window.location.href = thisUrl.href;
    } else {
      return this.props.history.push(AppURLProps.signin);
    }
  };
  render() {
    return (
      <>
        <AppPageTitle
          pageTitle={IntlUtil.getText(
            this._intl_ns_suc_home,
            "title.zoomConnect"
          )}
        />
        <div id="suc-layout-wrapper">
          <Navbar {...this.props} />
          <div id="suc-main-wrapper">
            <div id="suc-main-content">
              <PageHeader />
              <div id="suc-page-container">
                <div className="frame-content-zoom">
                  <div className="zoom-logo-wrapper m-b-20">
                    <Image src={ZoomLogo} />
                  </div>
                  <div className="zoom-text-wrapper m-l-20 m-r-20">
                    <span className="text-fw-regular">
                      {IntlUtil.getText(
                        this._intl_ns_suc_home,
                        "content.zoomInfoText1"
                      )}
                    </span>
                  </div>
                  <div className="zoom-button-wrapper m-t-20 m-l-20 m-r-20">
                    <PrimaryButton onClick={() => this.loadZoomSigninPage()}>
                      {IntlUtil.getText(
                        this._intl_ns_suc_home,
                        "content.connectToZoom"
                      )}
                    </PrimaryButton>
                  </div>
                  <div className="zoom-text-wrapper m-t-20 m-r-20 m-l-20">
                    <span className="text-fw-regular">
                      {IntlUtil.getText(
                        this._intl_ns_suc_home,
                        "content.zoomInfoText2"
                      )}
                    </span>
                  </div>
                  <div className="zoom-text-wrapper m-t-20 m-b-40 m-r-20 m-l-20">
                    <span className="text-fw-regular">
                      {IntlUtil.getText(
                        this._intl_ns_suc_home,
                        "content.zoomInfoText3"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ZoomSignin;
