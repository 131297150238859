import React, { Component } from "react";
import { AppURLProps } from "../settings/app-urls";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../settings/msal-config";
import TokenUtil from "../helpers/token-util";
import { connect } from "react-redux";
import { AppConfigProps } from "../../core/settings/app-config";
import { setLoginType } from "../actions/identity-actions";

class Signout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    await this.signout();
  }
  signout = async () => {
    let msalConfigObj = msalConfig;
    let loginDetails = JSON.parse(localStorage.getItem("loginDetailsHint"));

    if (loginDetails?.loginType === "zoomInDirectLogin") {
      if (loginDetails?.loginProvider !== "microsoft.com") {
        let azureActiveDirectoryClientAppId =
          process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_B2C_APP_ID;
        let azureActiveDirectoryB2CMicrosoftAuthority =
          process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_MICROSOFT_B2C_URL;
        msalConfigObj = {
          ...msalConfig,
          auth: {
            ...msalConfig.auth,
            clientId: azureActiveDirectoryClientAppId,
            authority: azureActiveDirectoryB2CMicrosoftAuthority,
          },
        };
      } else {
        let azureActiveDirectoryClientAppId =
          process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_ID;
        let azureActiveDirectoryMicrosoftAuthority =
          process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_MICROSOFT_URL;
        msalConfigObj = {
          ...msalConfig,
          auth: {
            ...msalConfig.auth,
            clientId: azureActiveDirectoryClientAppId,
            authority: azureActiveDirectoryMicrosoftAuthority,
          },
        };
      }
      let msal = new PublicClientApplication(msalConfigObj);
      let account = await msal.getAccountByUsername(
        TokenUtil.getIdentitySippioAccessTokenData()?.userEmail
      );
      TokenUtil.removeIdentityToken();
      localStorage.removeItem(
        AppConfigProps.identitySession.universalConnecterMicrosoftTokenKey
      );
      await msal.logoutRedirect({
        account,
      });
    } else {
      await TokenUtil.removeIdentityToken();
      localStorage.removeItem(
        AppConfigProps.identitySession.universalConnecterMicrosoftTokenKey
      );
      localStorage.clear();
      this.props.history.push(AppURLProps.signin);
    }
  };
  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => ({
  loginType: state.identityStore.loginType,
});
const mapActionToProps = {
  setLoginType,
};

export default connect(mapStateToProps, mapActionToProps)(Signout);
