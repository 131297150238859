import {
  checkIdentitySession,
  checkZoomIdentitySession,
} from "../actions/identity-actions";
import { AppConfigProps } from "../settings/app-config";
import TokenUtil from "./token-util";

class IdleTimer {
  constructor({ timeout, onTimeout, onExpired }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    // const expiredTime = parseInt(localStorage.getItem(AppConfigProps.identitySession.universalConnecterIdleTimerKey), 10);
    // if (expiredTime > 0 && expiredTime < Date.now()) {
    //     onExpired();
    //     return;
    // }
    this.eventHandler = this.updateExpiredTime.bind(this);
    this.sessionEventHandler = this.trackIdentitySession.bind(this);
    this.tracker();
    this.sessionTracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();
    this.trackIdentitySession();
    this.interval = setInterval(() => {
      const expiredTime = parseInt(
        localStorage.getItem(
          AppConfigProps.identitySession.universalConnecterIdleTimerKey
        ),
        10
      );
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
          this.cleanUpSession();
        }
      }
    }, 1000 * 60); // 1 Minute (60 sec) for start Interval
  }
  trackSessionTImer() {
    const tokenData = TokenUtil.getIdentitySippioAccessTokenData();
    const zoomTokenData = TokenUtil.getIdentitySippioZoomAccessTokenData();
    if (
      tokenData &&
      tokenData.userEmail &&
      tokenData.tokenExpiry >= Date.now() / 1000
    ) {
      if (
        tokenData.tokenExpiry - parseInt(Date.now() / 1000) <=
        AppConfigProps.identitySession.expiryTimerCheckRemainingSeconds // 10 mins
      ) {
        checkIdentitySession();
      }
    }
    if (
      zoomTokenData !== null &&
      zoomTokenData.userName &&
      zoomTokenData.zoomAccountEmail &&
      zoomTokenData.zoomTokenExpiry >= Date.now() / 1000
    ) {
      if (
        zoomTokenData.zoomTokenExpiry - parseInt(Date.now() / 1000) <=
        AppConfigProps.identitySession.expiryTimerCheckRemainingSeconds // 10 mins
      ) {
        checkZoomIdentitySession();
      }
    }
  }

  // track user session token
  // if tokenexpiring in 10 min we call renew API
  trackIdentitySession() {
    if (this.identitySessionTimerId) {
      clearTimeout(this.identitySessionTimerId);
    }
    this.identitySessionTimerId = setInterval(() => {
      this.trackSessionTImer();
    }, 5000); // 10 sec
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem(
        AppConfigProps.identitySession.universalConnecterIdleTimerKey,
        Date.now() + this.timeout * 1000
      );
    }, 300);
  }

  tracker() {
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
    window.addEventListener("touchmove", this.eventHandler); // touch move in mobile
  }
  sessionTracker() {
    window.addEventListener("mousemove", this.sessionEventHandler);
    window.addEventListener("scroll", this.sessionEventHandler);
    window.addEventListener("keydown", this.sessionEventHandler);
    window.addEventListener("touchmove", this.sessionEventHandler); // touch move in mobile
  }

  cleanUp() {
    localStorage.removeItem(
      AppConfigProps.identitySession.universalConnecterIdleTimerKey
    );
    clearInterval(this.interval);
    window.removeEventListener("mousemove", this.eventHandler);
    window.removeEventListener("scroll", this.eventHandler);
    window.removeEventListener("keydown", this.eventHandler);
    window.addEventListener("touchmove", this.eventHandler); // touch move in mobile
  }
  cleanUpSession() {
    localStorage.removeItem(
      AppConfigProps.identitySession.universalConnecterIdleTimerKey
    );
    clearInterval(this.interval);
    window.removeEventListener("mousemove", this.sessionEventHandler);
    window.removeEventListener("scroll", this.sessionEventHandler);
    window.removeEventListener("keydown", this.sessionEventHandler);
    window.removeEventListener("touchmove", this.sessionEventHandler); // touch move in mobile
  }
}

export default IdleTimer;
