import React, { Component } from "react";
import {  Text, DefaultButton } from "@fluentui/react";
import IntlUtil from "../helpers/intl-util";
import { AppURLProps } from "../settings/app-urls";
import { AppPageTitle } from '../components/app-page-title'

class SessionExpired extends Component {
    _intl_ns_common = "common";
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div id="error-page">
                <AppPageTitle pageTitle={IntlUtil.getText(this._intl_ns_common, "title.pageNotFound")} />
                <header className="error-page-header">
						<div className="error-page-brand">
							{IntlUtil.getText(this._intl_ns_common, "title.appName")}
						</div>
        </header>                <div className="error-page-content">
                    <div className="error-page-segment">
                        <div className="m-b-20">
                            <Text className="error-page-title">Session Expired</Text>
                        </div>
                        <div className="m-b-30">
                            <Text className="error-page-text">
                                Your Session is expired, please Signin again.
              </Text>
                        </div>
                        <div className="m-b-20">
                            <DefaultButton
                                onClick={() => this.props.history.push(AppURLProps.signin)}
                                text="Go to Sign in Page"
                                className="error-page-button"
                            ></DefaultButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SessionExpired;
