/**
Project: Phone Connect (c)
Title: Table Pagination
Description: Component for displaying the table pagination
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import React, { Component } from "react";
import { IconButton, Dropdown } from "@fluentui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleChevronLeft12Icon,
  DoubleChevronRight12Icon,
} from "@fluentui/react-icons";
import IntlUtil from "../../../core/helpers/intl-util";
import { UniversalConnecterConstants } from "../settings/universal-connecter-constants";

class TablePagination extends Component {
  _intl_ns_suc_common = "suc_common";

  constructor(props) {
    super(props);
    this.state = {
      pageLimitOptions: this.initPageLimitOptions(),
    };
  }

  initPageLimitOptions = () => {
    let pageLimitOptions = UniversalConnecterConstants.TABLE_DATA.PAGE_LIMIT_OPTIONS;
    let dropdownOptions = [];
    if (pageLimitOptions && pageLimitOptions.length > 0) {
      pageLimitOptions.forEach((val) => {
        let option = {
          key: val,
          text:
            val +
            " " +
            IntlUtil.getText(
              this._intl_ns_suc_common,
              "content.recordsPerPage"
            ),
        };
        dropdownOptions.push(option);
      });
    }
    return dropdownOptions;
  };

  handlePageOffsetChange = (pageOffset) => {
    if (pageOffset >= 0) {
      this.props.handlePageOffsetChange(pageOffset);
    }
  };

  handlePageLimitChange = (event, pageLimit) => {
    if (pageLimit && pageLimit > 0) {
      this.props.handlePageLimitChange(pageLimit);
    }
  };

  render() {
    if (this.props.totalRecords) {
      let pageOffset = this.props.pageOffset;
      const pageLimit = this.props.pageLimit;
      //const recordCount = this.props.recordCount;
      const totalRecords = this.props.totalRecords;
      let pageCount = Math.ceil(totalRecords / pageLimit);
      // const pageNumbers = [];
      //   for (let i = 1; i <= Math.ceil(totalRecords / pageLimit); i++) {
      //     pageNumbers.push(i);
      //   }

      if (pageOffset < 0 || pageOffset > pageCount - 1) {
        pageOffset = 0;
      }

      const firstItemDisableStatus = pageOffset === 0 ? true : false;
      const firstItemClickCallback = () => this.handlePageOffsetChange(0);

      const lastItemDisableStatus = pageOffset === pageCount - 1 ? true : false;
      const lastItemClickCallback = () =>
        this.handlePageOffsetChange(pageCount - 1);

      const previousItemDisableStatus = pageOffset === 0 ? true : false;
      const previousItemClickCallback = () =>
        this.handlePageOffsetChange(pageOffset - 1);

      const nextItemDisableStatus = pageOffset === pageCount - 1 ? true : false;
      const nextItemClickCallback = () =>
        this.handlePageOffsetChange(pageOffset + 1);

      let pageNumArray = [];
      if (pageCount <= 5) {
        for (let i = 1; i <= pageCount; i++) {
          pageNumArray.push(i);
        }
      } else if (pageCount > 5 && pageCount <= 10) {
        for (let j = 1; j <= 2; j++) {
          pageNumArray.push(j);
        }
        for (let k = pageCount - 1; k <= pageCount; k++) {
          pageNumArray.push(k);
        }
      } else {
        let midNum1 = parseInt(pageCount / 2);
        let midNum2 = parseInt(midNum1 + 1);
        let firstNum = parseInt(midNum1 / 2);
        let lastNum = parseInt(midNum2 + midNum2 / 2);
        pageNumArray.push(firstNum);
        pageNumArray.push(midNum1);
        pageNumArray.push(midNum2);
        pageNumArray.push(lastNum);
      }
      let pageRecordsCount = pageLimit * (pageOffset + 1);
      if (pageRecordsCount >= totalRecords) {
        pageRecordsCount = totalRecords
      }

      return (
        <div className="page-frame-table-footer">
          <span className="page-frame-table-number">
            {totalRecords > 0 ? (<>
              <span className="text-fs-medium">{((pageOffset + 1) * pageLimit) - pageLimit + 1}</span>  to <span className="text-fs-medium">{pageRecordsCount}</span> of <span className="text-fs-medium">{totalRecords}</span></>) : null}
          </span>
          <div className="page-frame-table-page-offset">
            <IconButton
              onClick={firstItemClickCallback}
              allowDisabledFocus
              disabled={firstItemDisableStatus}
              title={IntlUtil.getText(this._intl_ns_suc_common,"content.firstPage")}
            >
              <DoubleChevronLeft12Icon />
            </IconButton>
            <IconButton
              onClick={previousItemClickCallback}
              allowDisabledFocus
              disabled={previousItemDisableStatus}
              title={IntlUtil.getText(this._intl_ns_suc_common,"content.previousPage")}

            >
              <ChevronLeftIcon />
            </IconButton>

            {/*
            pageNumArray.map((element, index) => {
              let pageItemEnableStatus =
                element - 1 === pageOffset ? true : false;
              return (
                <ActionButton
                  disabled={pageItemEnableStatus}
                  key={`page-table-${index}`}
                  onClick={() => this.handlePageOffsetChange(element - 1)}
                  text={element}
                ></ActionButton>
              );
            })
            */}
            <IconButton
              onClick={nextItemClickCallback}
              allowDisabledFocus
              disabled={nextItemDisableStatus}
              title={IntlUtil.getText(this._intl_ns_suc_common,"content.nextPage")}
            >
              <ChevronRightIcon />
            </IconButton>
            <IconButton
              onClick={lastItemClickCallback}
              allowDisabledFocus
              disabled={lastItemDisableStatus}
              title={IntlUtil.getText(this._intl_ns_suc_common,"content.lastPage")}
            >
              <DoubleChevronRight12Icon />
            </IconButton>
          </div>

          <div className="page-frame-table-page-limit">
            <Dropdown
              id="pageLimit"
              name="pageLimit"
              options={this.state.pageLimitOptions}
              defaultSelectedKey={this.props.pageLimit}
              onChange={(event, option) => {
                this.handlePageLimitChange(event, parseInt(option.key));
              }}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
export default TablePagination;
