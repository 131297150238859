/**
Project: Phone Connect (c)
Title: Pricate Route  
Description: Component for hadling the routes of the application which are secure 
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import api from "../core/helpers/api-services";
import { AppConfigProps } from "../core/settings/app-config";
import TokenUtil from "../core/helpers/token-util";
import RequestUtil from "../core/helpers/request-util";
import {
  isIdentitySessionValid,
  isZoomIdentitySessionValid,
  renewIdentitySession,
} from "../core/actions/identity-actions";
import { AppURLProps } from "../core/settings/app-urls";
//import { manageError } from "../core/actions/common-actions";

const _axiosSource = api.CancelToken.source();
const _cancelToken = { cancelToken: _axiosSource.token };

export const PrivateRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    window.addEventListener("beforeunload", (data) =>
      localStorage.setItem(
        AppConfigProps.identitySession.universalConnecterIdleTimerKey,
        Date.now() + process.env.REACT_APP_IDLE_TIMEOUT_SECONDS * 1000
      )
    );
    return () => {
      window.removeEventListener("beforeunload", (data) =>
        localStorage.setItem(
          AppConfigProps.identitySession.universalConnecterIdleTimerKey,
          Date.now() + process.env.REACT_APP_IDLE_TIMEOUT_SECONDS * 1000
        )
      );
    };
  }, []);

  //access log
  return (
    <Route
      {...rest}
      render={(props) => {
        const tokenData = TokenUtil.getIdentitySippioAccessTokenData();
        const isValidSession = isIdentitySessionValid();
        const isZoomSessionValid = isZoomIdentitySessionValid();
        let loginDetails = JSON.parse(localStorage.getItem("loginDetailsHint"));
        let validTime = true;
        let idleTime = parseInt(
          localStorage.getItem(
            AppConfigProps.identitySession.universalConnecterIdleTimerKey
          )
        );
        if (idleTime < Date.now()) validTime = false;
        if (loginDetails?.loginType === "zoomInDirectLogin") {
          if (
            isValidSession === true &&
            validTime &&
            isZoomSessionValid === true
          ) {
            RequestUtil.setRequestHeaders();
            if (
              tokenData.tokenExpiry - parseInt(Date.now() / 1000) <=
              AppConfigProps.identitySession.expiryCheckRemainingSeconds
            ) {
              renewIdentitySession(_cancelToken);
            }
            // authorised so return component
            return <Component {...props} />;
          } else {
            if (
              isZoomSessionValid === false &&
              validTime &&
              isValidSession === true
            ) {
              return (
                <Redirect
                  to={{
                    pathname: AppURLProps.zoomSignin,
                    state: { from: props.location },
                  }}
                />
              );
            } else if (
              isZoomSessionValid === false &&
              isValidSession === false
            ) {
              TokenUtil.removeIdentityToken();
              return (
                <Redirect
                  to={{
                    pathname: AppURLProps.signin,
                    state: { from: props.location },
                  }}
                />
              );
            } else {
              TokenUtil.removeIdentityToken();
              return (
                <Redirect
                  to={{
                    pathname: AppURLProps.signin,
                    state: { from: props.location },
                  }}
                />
              );
            }
          }
        } else {
          if (isZoomSessionValid === true) {
            RequestUtil.setRequestHeaders();
            if (
              tokenData.tokenExpiry - parseInt(Date.now() / 1000) <=
              AppConfigProps.identitySession.expiryCheckRemainingSeconds
            ) {
              renewIdentitySession(_cancelToken);
            }
            // authorised so return component
            return <Component {...props} />;
          } else {
            TokenUtil.removeIdentityToken();
            return (
              <Redirect
                to={{
                  pathname: AppURLProps.signin,
                  state: { from: props.location },
                }}
              />
            );
          }
        }
      }}
    />
  );
};

export default PrivateRoute;
