/**
Project: Phone Connect (c)
Title: List Subscriber Numbers 
Description: Component for displaying the list of Subscriber Numbers with search, sort and pagination 
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import React, { Component } from "react";
import api from "../../../core/helpers/api-services";
import { connect } from "react-redux";
import {
	DetailsList,
	SelectionMode,
	DetailsListLayoutMode,
	MessageBar,
	MessageBarType,
	ProgressIndicator,
	ScrollablePane,
	ScrollbarVisibility,
	ConstrainMode,
	Text,
	PrimaryButton,
	Sticky,
	StickyPositionType,
	DefaultButton,
	Pivot,
	PivotItem,
	Dialog,
	DialogType,
	DialogFooter,
	Checkbox,
	SearchBox,
	Link,
} from "@fluentui/react";
import PageLoader from "../../common/helpers/page-loader";
import PageUtil from "../../../core/helpers/page-util";
import ListUtil from "../../common/helpers/list-util";
import IntlUtil from "../../../core/helpers/intl-util";
import TablePagination from "../../common/helpers/table-pagination";
import { AppConfigProps } from "../../../core/settings/app-config";
import {
	NumberTableColumns,
	NumberPublishStatusTabs,
} from "../settings/number-table-columns";
import { manageError } from "../../../core/actions/common-actions";
import { UniversalConnecterConstants } from "../../common/settings/universal-connecter-constants";
import {
	searchNumbers,
	getNumbers,
	removeNumbers,
	publishNumbers,
} from "../actions/number-actions";
import { AppPageTitle } from "../../../core/components/app-page-title";
import PageOverlay from "../../common/helpers/page-overlay";
import _  from "lodash";
import ResponseUtil from "../../../core/helpers/response-util";
import NumberUpdateStatusModal from "../helpers/number-update-status-modal";
import { NumberUpdateStatusTableColumns } from "../settings/number-update-status-table-columns";
import { AppURLProps } from "../../../core/settings/app-urls";

class NumberList extends Component {
	_isMounted = false;
	_axiosSource = api.CancelToken.source();
	_cancelToken = { cancelToken: this._axiosSource.token };
	_intl_ns_common = "common";
	_intl_ns_suc_common = "suc_common";
	_intl_ns_suc_numbers = "suc_numbers";

	constructor(props) {
		super(props);
		this.subscriberNumberListPageRef = React.createRef();
		this.actionRef = React.createRef();
		this.state = {
			isPageDataFetched: false,
			isListDataFetched: false,
			isFormDataSubmitted: false,
			tableHeaderColumns: null,
			numbersAllRecords: null,
			numbersDisplayRecords: null,
			numbersPageRecords: null,
			numberCount: { successCount: 0, failureCount: 0 },
			searchValue: "",
			searchKeyword: "",
			numberSubmitStatus: null,
			numberSubmitErrors: null,
			numberPublishStatusTabs: this.initNumberPublishStatusTabs(),
			pageOffset: UniversalConnecterConstants.TABLE_DATA.PAGE_OFFSET_DEFAULT,
			pageLimit: UniversalConnecterConstants.TABLE_DATA.PAGE_LIMIT_DEFAULT,
			sortedColumn: null,
			updatedNumberRecords: [],
			isRemoveNumConfirmationDialogHidden: true,
			isPublishNumConfirmationDialogHidden: true,
			tabSelected: null,
			numbersTabRecords: null,
			publishedHeaderCheckStatus: false,
			unpublishedHeaderCheckStatus: false,
			checkedNumberRecords: null,
			isPublishedNumberCheckEnabled: false,
			isUnpublishedNumberCheckEnabled: false,
			errorMessage: null,
			successMessage: null,
			isModalOpen: false,
			isLinkAvailable: false,
		};
	}

	setStateAsync = (state) => {
		if (this._isMounted) {
			return new Promise((resolve) => {
				this.setState(state, resolve);
			});
		}
	};

	async componentDidMount() {
		this._isMounted = true;
		PageUtil.scrollIntoView(this.subscriberNumberListPageRef);
		await this.loadPageData();
	}

	clearAlert = async () => {
		await this.setStateAsync({
			numberSubmitStatus: null,
			numberSubmitErrors: null,
			errorMessage: null,
			successMessage: null,
			isLinkAvailable: false,
		});
	};

	componentWillUnmount() {
		this._isMounted = false;
		this._axiosSource.cancel(
			IntlUtil.getText(
				this._intl_ns_common,
				"notification.warning.requestCancelled",
			),
		);
	}

	loadPageData = async () => {
		await this.setStateAsync({
			tableHeaderColumns: this.initTablePublishedHeaderColumns(),
		});
		await this.setStateAsync({ isPageDataFetched: false });
		await this.fetchNumbers();
		await this.setStateAsync({ isPageDataFetched: true });
	};

	initTablePublishedHeaderColumns = () => {
		let publishedTableColumns = NumberTableColumns(this._intl_ns_suc_numbers);
		let checkboxData = {
			key: "checkbox",
			name: (
				<div>
					<Checkbox
						className="m-t-10"
						onChange={this.handlePublishedHeaderCheck}
						checked={this.state.publishedHeaderCheckStatus}
					/>
				</div>
			),
			data: "string",
			minWidth: 20,
			maxWidth: 20,
			isRowHeader: true,
			isResizable: false,
			isSortable: false,
			isSorted: false,
			isSortedDescending: false,
		};
		publishedTableColumns.unshift(checkboxData);
		return publishedTableColumns;
	};

	initTableUnpublishedHeaderColumns = () => {
		let unpublishedTableColumns = NumberTableColumns(this._intl_ns_suc_numbers);
		let checkboxData = {
			key: "checkbox",
			name: (
				<div>
					<Checkbox
						className="m-t-10"
						onChange={this.handleUnpublishedHeaderCheck}
						checked={this.state.unpublishedHeaderCheckStatus}
					/>
				</div>
			),
			data: "string",
			minWidth: 20,
			maxWidth: 20,
			isRowHeader: true,
			isResizable: false,
			isSortable: false,
			isSorted: false,
			isSortedDescending: false,
		};
		unpublishedTableColumns.unshift(checkboxData);
		return unpublishedTableColumns;
	};

	initTableAllHeaderColumns = () => {
		let allTableColumns = NumberTableColumns(this._intl_ns_suc_numbers);
		let checkboxData = {
			key: "checkbox",
			name: "",
			data: "string",
			minWidth: 20,
			maxWidth: 20,
			isRowHeader: true,
			isResizable: false,
			isSortable: false,
			isSorted: false,
			isSortedDescending: false,
		};
		allTableColumns.unshift(checkboxData);
		return allTableColumns;
	};

	initNumberPublishStatusTabs = () => {
		return NumberPublishStatusTabs(this._intl_ns_suc_numbers);
	};

	filteredNumberRecords = async (numberRecords) => {
		let filteredRecords = [];
		if (numberRecords) {
			numberRecords.forEach((rec) => {
				if (rec && rec.isChecked === true) {
					filteredRecords.push({ numberId: rec.numberId });
				}
			});
		}
		return filteredRecords;
	};

	handlePublishedHeaderCheck = async (e, option) => {
		await this.setStateAsync({ publishedHeaderCheckStatus: option });
		if (
			this.state.tableHeaderColumns === null ||
			this.state.tableHeaderColumns === undefined
		) {
			await this.setStateAsync({
				tableHeaderColumns: this.initTablePublishedHeaderColumns(),
			});
		} else {
			let headers = this.state.tableHeaderColumns.map((tab) => {
				if (tab.key === "checkbox") {
					tab.name = (
						<div>
							<Checkbox
								className="m-t-10"
								onChange={this.handlePublishedHeaderCheck}
								checked={this.state.publishedHeaderCheckStatus}
							/>
						</div>
					);
					return { ...tab };
				} else {
					return tab;
				}
			});
			await this.setStateAsync({ tableHeaderColumns: headers });
		}
		if (this.state.numbersTabRecords) {
			await this.setStateAsync((prevState) => {
				let result = [...prevState.numbersTabRecords];
				let revisedRecords = [];
				result.forEach((rec) => {
					if (rec) {
						rec.isChecked = option;
					}
					revisedRecords.push({ ...rec });
					return { numbersTabRecords: revisedRecords };
				});
			});

			let searchedRecords = await searchNumbers(
				this.state.numbersTabRecords,
				this.state.searchKeyword,
			);
			let isPublishedNumberCheckEnabled = this.state.numbersTabRecords.some(
				(x) =>
					x.publishStatus?.toLowerCase().trim() ===
						UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED?.toLowerCase().trim() &&
					x.isChecked === true,
			);
			let isUnpublishedNumberCheckEnabled = this.state.numbersTabRecords.some(
				(x) =>
					(x.publishStatus?.toLowerCase().trim() ===
						UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED?.toLowerCase().trim() ||
						x.publishStatus?.toLowerCase().trim() ===
							UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.NOT_PUBLISHED?.toLowerCase().trim()) &&
					x.isChecked === true,
			);

			await this.setStateAsync({
				numbersTabRecords: searchedRecords,
				isPublishedNumberCheckEnabled: isPublishedNumberCheckEnabled,
				isUnpublishedNumberCheckEnabled: isUnpublishedNumberCheckEnabled,
			});

			let sortedRecords = await ListUtil.sortListRecords(
				searchedRecords,
				this.state.sortedColumn.fieldName,
				this.state.sortedColumn.isSortedDescending
					? UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.DESCENDING
					: UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.ASCENDING,
				this.state.sortedColumn.data,
			);
			await this.setStateAsync({
				numbersDisplayRecords: sortedRecords,
			});

			let slicedRecords = await ListUtil.sliceListRecords(
				this.state.numbersDisplayRecords,
				this.state.pageOffset,
				this.state.pageLimit,
			);
			await this.setStateAsync({ numbersPageRecords: slicedRecords });
		}
	};

	handleUnpublishedHeaderCheck = async (e, option) => {
		await this.setStateAsync({ unpublishedHeaderCheckStatus: option });
		if (
			this.state.tableHeaderColumns === null ||
			this.state.tableHeaderColumns === undefined
		) {
			await this.setStateAsync({
				tableHeaderColumns: this.initTableUnpublishedHeaderColumns(),
			});
		} else {
			let headers = this.state.tableHeaderColumns.map((tab) => {
				if (tab.key === "checkbox") {
					tab.name = (
						<div>
							<Checkbox
								className="m-t-10"
								onChange={this.handleUnpublishedHeaderCheck}
								checked={this.state.unpublishedHeaderCheckStatus}
							/>
						</div>
					);
					return { ...tab };
				} else {
					return tab;
				}
			});
			await this.setStateAsync({ tableHeaderColumns: headers });
		}
		if (this.state.numbersTabRecords) {
			await this.setStateAsync((prevState) => {
				let result = [...prevState.numbersTabRecords];
				let revisedRecords = [];
				result.forEach((rec) => {
					if (rec) {
						rec.isChecked = option;
					}
					revisedRecords.push({ ...rec });
					return { numbersTabRecords: revisedRecords };
				});
			});

			let searchedRecords = await searchNumbers(
				this.state.numbersTabRecords,
				this.state.searchKeyword,
			);
			await this.setStateAsync({ numbersTabRecords: searchedRecords });
			let isPublishedNumberCheckEnabled = this.state.numbersTabRecords.some(
				(x) =>
					x.publishStatus?.toLowerCase().trim() ===
						UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED?.toLowerCase().trim() &&
					x.isChecked === true,
			);
			let isUnpublishedNumberCheckEnabled = this.state.numbersTabRecords.some(
				(x) =>
					(x.publishStatus?.toLowerCase().trim() ===
						UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED?.toLowerCase().trim() ||
						x.publishStatus?.toLowerCase().trim() ===
							UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.NOT_PUBLISHED?.toLowerCase().trim()) &&
					x.isChecked === true,
			);

			await this.setStateAsync({
				numbersTabRecords: searchedRecords,
				isPublishedNumberCheckEnabled: isPublishedNumberCheckEnabled,
				isUnpublishedNumberCheckEnabled: isUnpublishedNumberCheckEnabled,
			});

			let sortedRecords = await ListUtil.sortListRecords(
				searchedRecords,
				this.state.sortedColumn.fieldName,
				this.state.sortedColumn.isSortedDescending
					? UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.DESCENDING
					: UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.ASCENDING,
				this.state.sortedColumn.data,
			);
			await this.setStateAsync({
				numbersDisplayRecords: sortedRecords,
			});

			let slicedRecords = await ListUtil.sliceListRecords(
				this.state.numbersDisplayRecords,
				this.state.pageOffset,
				this.state.pageLimit,
			);
			await this.setStateAsync({ numbersPageRecords: slicedRecords });
		}
	};

	handleResetNumbersCheck = async (tabRecords) => {
		let records = [];
		PageUtil.scrollIntoView(this.subscriberNumberListPageRef);
		if (tabRecords && tabRecords.length > 0) {
			tabRecords.forEach((rec) => {
				rec.isChecked = false;
				records.push(rec);
			});
			if (
				this.state.tabSelected.tabValue ===
				UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED
			) {
				await this.setStateAsync({ publishedHeaderCheckStatus: false });
				let headers = this.state.tableHeaderColumns.map((tab) => {
					if (tab.key === "checkbox") {
						tab.name = (
							<div>
								<Checkbox
									className="m-t-10"
									onChange={this.handlePublishedHeaderCheck}
									checked={this.state.publishedHeaderCheckStatus}
								/>
							</div>
						);
						return { ...tab };
					} else {
						return tab;
					}
				});
				await this.setStateAsync({ tableHeaderColumns: headers });
			}
			if (
				this.state.tabSelected.tabValue ===
				UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED
			) {
				await this.setStateAsync({ unpublishedHeaderCheckStatus: false });
				let headers = this.state.tableHeaderColumns.map((tab) => {
					if (tab.key === "checkbox") {
						tab.name = (
							<div>
								<Checkbox
									className="m-t-10"
									onChange={this.handleUnpublishedHeaderCheck}
									checked={this.state.unpublishedHeaderCheckStatus}
								/>
							</div>
						);
						return { ...tab };
					} else {
						return tab;
					}
				});
				await this.setStateAsync({ tableHeaderColumns: headers });
			}

			let searchedRecords = await searchNumbers(
				this.state.numbersTabRecords,
				this.state.searchKeyword,
			);
			await this.setStateAsync({ numbersTabRecords: searchedRecords });
			let isPublishedNumberCheckEnabled = this.state.numbersTabRecords.some(
				(x) =>
					x.publishStatus?.toLowerCase().trim() ===
						UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED?.toLowerCase().trim() &&
					x.isChecked === true,
			);
			let isUnpublishedNumberCheckEnabled = this.state.numbersTabRecords.some(
				(x) =>
					(x.publishStatus?.toLowerCase().trim() ===
						UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED?.toLowerCase().trim() ||
						x.publishStatus?.toLowerCase().trim() ===
							UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.NOT_PUBLISHED?.toLowerCase().trim()) &&
					x.isChecked === true,
			);

			await this.setStateAsync({
				numbersTabRecords: searchedRecords,
				isPublishedNumberCheckEnabled: isPublishedNumberCheckEnabled,
				isUnpublishedNumberCheckEnabled: isUnpublishedNumberCheckEnabled,
			});

			let sortedRecords = await ListUtil.sortListRecords(
				searchedRecords,
				this.state.sortedColumn.fieldName,
				this.state.sortedColumn.isSortedDescending
					? UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.DESCENDING
					: UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.ASCENDING,
				this.state.sortedColumn.data,
			);
			await this.setStateAsync({
				numbersDisplayRecords: sortedRecords,
			});

			let slicedRecords = await ListUtil.sliceListRecords(
				this.state.numbersDisplayRecords,
				this.state.pageOffset,
				this.state.pageLimit,
			);
			await this.setStateAsync({ numbersPageRecords: slicedRecords });
		}
	};

	fetchNumbers = async () => {
		await getNumbers(this._cancelToken)
			.then(async (res) => {
				if (
					res &&
					res.status === AppConfigProps.httpStatusCode.ok &&
					res.data &&
					res.data.records
				) {
					let records = [];
					res.data.records.forEach((rec) => {
						rec.isChecked = false;
						records.push(rec);
					});
					await this.setStateAsync({ publishedHeaderCheckStatus: false });
					await this.setStateAsync({ unpublishedHeaderCheckStatus: false });

					await this.setStateAsync({ numbersAllRecords: records });
					if (this.state.numberPublishStatusTabs) {
						if (
							this.state.tabSelected === null ||
							this.state.tabSelected === undefined
						) {
							let tabSelected = _.find(this.state.numberPublishStatusTabs, {
								tabValue: UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.ALL,
							});
							await this.setStateAsync({ tabSelected: tabSelected });
						}
					}
					if (
						this.state.tabSelected.tabValue ===
						UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED
					) {
						if (
							this.state.tableHeaderColumns === null ||
							this.state.tableHeaderColumns === undefined
						) {
							await this.setStateAsync({
								tableHeaderColumns: this.initTablePublishedHeaderColumns(),
							});
						} else {
							let headers = this.state.tableHeaderColumns.map((tab) => {
								if (tab.key === "checkbox") {
									tab.name = (
										<div>
											<Checkbox
												className="m-t-10"
												onChange={this.handlePublishedHeaderCheck}
												checked={this.state.publishedHeaderCheckStatus}
											/>
										</div>
									);
									return { ...tab };
								} else {
									return tab;
								}
							});
							await this.setStateAsync({ tableHeaderColumns: headers });
						}
					} else if (
						this.state.tabSelected.tabValue ===
						UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED
					) {
						if (
							this.state.tableHeaderColumns === null ||
							this.state.tableHeaderColumns === undefined
						) {
							await this.setStateAsync({
								tableHeaderColumns: this.initTableUnpublishedHeaderColumns(),
							});
						} else {
							let headers = this.state.tableHeaderColumns.map((tab) => {
								if (tab.key === "checkbox") {
									tab.name = (
										<div>
											<Checkbox
												className="m-t-10"
												onChange={this.handleUnpublishedHeaderCheck}
												checked={this.state.unpublishedHeaderCheckStatus}
											/>
										</div>
									);
									return { ...tab };
								} else {
									return tab;
								}
							});
							await this.setStateAsync({ tableHeaderColumns: headers });
						}
					} else {
						if (
							this.state.tableHeaderColumns === null ||
							this.state.tableHeaderColumns === undefined
						) {
							await this.setStateAsync({
								tableHeaderColumns: this.initTableAllHeaderColumns(),
							});
						}
					}

					let filteredRecords = await this.filterSubscriberNumbers(
						this.state.numbersAllRecords,
						this.state.tabSelected,
					);
					await this.setStateAsync({ numbersTabRecords: filteredRecords });
					let isPublishedNumberCheckEnabled = this.state.numbersTabRecords.some(
						(x) =>
							x.publishStatus?.toLowerCase().trim() ===
								UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED?.toLowerCase().trim() &&
							x.isChecked === true,
					);
					let isUnpublishedNumberCheckEnabled =
						this.state.numbersTabRecords.some(
							(x) =>
								(x.publishStatus?.toLowerCase().trim() ===
									UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED?.toLowerCase().trim() ||
									x.publishStatus?.toLowerCase().trim() ===
										UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.NOT_PUBLISHED?.toLowerCase().trim()) &&
								x.isChecked === true,
						);

					await this.setStateAsync({
						isPublishedNumberCheckEnabled: isPublishedNumberCheckEnabled,
						isUnpublishedNumberCheckEnabled: isUnpublishedNumberCheckEnabled,
					});

					let searchedRecords = await searchNumbers(
						// this.state.numbersAllRecords,
						filteredRecords,
						this.state.searchKeyword,
					);
					if (
						this.state.sortedColumn === null ||
						this.state.sortedColumn === undefined
					) {
						let sortedColumn = await this.state.tableHeaderColumns[1];
						await this.setStateAsync({ sortedColumn: sortedColumn });
					}
					let sortedRecords = await ListUtil.sortListRecords(
						searchedRecords,
						this.state.sortedColumn.fieldName,
						this.state.sortedColumn.isSortedDescending
							? UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.DESCENDING
							: UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.ASCENDING,
						this.state.sortedColumn.data,
					);
					await this.setStateAsync({
						numbersDisplayRecords: sortedRecords,
					});

					let slicedRecords = await ListUtil.sliceListRecords(
						this.state.numbersDisplayRecords,
						this.state.pageOffset,
						this.state.pageLimit,
					);
					await this.setStateAsync({ numbersPageRecords: slicedRecords });

					await this.setStateAsync({ isListDataFetched: true });
				} else {
					await manageError(res, this.props.history);
				}
			})
			.catch(async (err) => {
				await manageError(err, this.props.history);
			});
	};

	handleSearchChange = async (e) => {
		e.preventDefault();
		await this.setStateAsync({ searchValue: e.target.value });
	};

	handleSearchSubmit = async (e) => {
		e.preventDefault();
		await this.setStateAsync({ isListDataFetched: false });
		if (
			this.state.numbersTabRecords &&
			this.state.numbersTabRecords.length > 0
		) {
			let searchKeyword = this.state.searchValue;
			await this.setStateAsync({ searchKeyword: searchKeyword });
			let searchedRecords = await searchNumbers(
				this.state.numbersTabRecords,
				this.state.searchKeyword,
			);

			let sortedRecords = await ListUtil.sortListRecords(
				searchedRecords,
				this.state.sortedColumn.fieldName,
				this.state.sortedColumn.isSortedDescending
					? UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.DESCENDING
					: UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.ASCENDING,
				this.state.sortedColumn.data,
			);
			await this.setStateAsync({ numbersDisplayRecords: sortedRecords });

			let pageOffset = 0;
			let slicedRecords = await ListUtil.sliceListRecords(
				this.state.numbersDisplayRecords,
				pageOffset,
				this.state.pageLimit,
			);
			await this.setStateAsync({ numbersPageRecords: slicedRecords });
			await this.setStateAsync({ pageOffset: pageOffset });
		}
		await this.setStateAsync({ isListDataFetched: true });
	};

	handleSearchCancel = async (e) => {
		e.preventDefault();
		await this.setStateAsync({ isListDataFetched: false });
		let filteredRecords = await this.filterSubscriberNumbers(
			this.state.numbersAllRecords,
			this.state.tabSelected,
		);
		await this.setStateAsync({ numbersTabRecords: filteredRecords });
		let sortedRecords = await ListUtil.sortListRecords(
			this.state.numbersTabRecords,
			this.state.sortedColumn.fieldName,
			this.state.sortedColumn.isSortedDescending
				? UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.DESCENDING
				: UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.ASCENDING,
			this.state.sortedColumn.data,
		);
		await this.setStateAsync({ numbersDisplayRecords: sortedRecords });

		let pageOffset = 0;
		let slicedRecords = await ListUtil.sliceListRecords(
			this.state.numbersDisplayRecords,
			pageOffset,
			this.state.pageLimit,
		);
		await this.setStateAsync({ numbersPageRecords: slicedRecords });
		await this.setStateAsync({ searchKeyword: "", searchValue: "" });
		await this.setStateAsync({ pageOffset: pageOffset });
		await this.setStateAsync({ isListDataFetched: true });
	};

	handleSortChange = async (e, column) => {
		if (column && column.isSortable) {
			await this.setStateAsync({ isListDataFetched: false });
			if (column.isSorted === true) {
				column.isSortedDescending = !column.isSortedDescending;
			} else {
				column.isSorted = true;
				column.isSortedDescending = false;
			}
			let sortedRecords = await ListUtil.sortListRecords(
				this.state.numbersDisplayRecords,
				column.fieldName,
				column.isSortedDescending
					? UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.DESCENDING
					: UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.ASCENDING,
				column.data,
			);
			await this.setStateAsync({ numbersDisplayRecords: sortedRecords });

			let prevColumnIndex = null;
			this.state.tableHeaderColumns.forEach((col, index) => {
				if (col.fieldName === this.state.sortedColumn.fieldName) {
					prevColumnIndex = index;
					return;
				}
			});
			let currColumnIndex = null;
			this.state.tableHeaderColumns.forEach((col, index) => {
				if (col.fieldName === column.fieldName) {
					currColumnIndex = index;
					return;
				}
			});
			if (prevColumnIndex !== null && currColumnIndex !== null) {
				let prevColumnElement = this.state.tableHeaderColumns[prevColumnIndex];
				prevColumnElement.isSorted = false;
				prevColumnElement.isSortedDescending = false;
				if (this._isMounted) {
					await this.setState((prevState) => {
						const result = [...prevState.tableHeaderColumns];
						result[prevColumnIndex] = prevColumnElement;
						result[currColumnIndex] = column;
						return { tableHeaderColumns: result };
					});
				}
			}

			await this.setStateAsync({ sortedColumn: column });

			let pageOffset = 0;
			let slicedRecords = await ListUtil.sliceListRecords(
				this.state.numbersDisplayRecords,
				pageOffset,
				this.state.pageLimit,
			);
			await this.setStateAsync({ numbersPageRecords: slicedRecords });
			await this.setStateAsync({ searchValue: this.state.searchKeyword });
			await this.setStateAsync({ pageOffset: pageOffset });
			await this.setStateAsync({ isListDataFetched: true });
		}
	};

	handleRemoveNumberCheck = async () => {
		let filteredNumbers = await this.filteredNumberRecords(
			this.state.numbersTabRecords,
		);
		await this.setStateAsync({ checkedNumberRecords: filteredNumbers });
		if (filteredNumbers && filteredNumbers.length > 0) {
			if (
				filteredNumbers.length <=
				UniversalConnecterConstants.NUMBER.MAXIMUM_PHONE_NUMBER_ARRAY_LENGTH
			) {
				await this.setStateAsync({
					isRemoveNumConfirmationDialogHidden: false,
				});
			} else {
				let errorMessage = IntlUtil.getText(
					this._intl_ns_suc_numbers,
					"notification.error.numbersMaxInvalid",
				);
				await this.setStateAsync({
					errorMessage: errorMessage,
					numberSubmitStatus:
						UniversalConnecterConstants.FORM_SUBMIT_STATUS.FAILURE,
				});
			}
		}
	};

	handlePublishNumberCheck = async (e) => {
		let filteredNumbers = await this.filteredNumberRecords(
			this.state.numbersTabRecords,
		);
		await this.setStateAsync({ checkedNumberRecords: filteredNumbers });
		if (filteredNumbers && filteredNumbers.length > 0) {
			if (
				filteredNumbers.length <=
				UniversalConnecterConstants.NUMBER.MAXIMUM_PHONE_NUMBER_ARRAY_LENGTH
			) {
				await this.setStateAsync({
					isPublishNumConfirmationDialogHidden: false,
				});
			} else {
				let errorMessage = IntlUtil.getText(
					this._intl_ns_suc_numbers,
					"notification.error.numbersMaxInvalid",
				);
				await this.setStateAsync({
					errorMessage: errorMessage,
					numberSubmitStatus:
						UniversalConnecterConstants.FORM_SUBMIT_STATUS.FAILURE,
				});
			}
		}
	};

	handleRemoveNumberFormSubmit = async () => {
		await this.clearAlert();
		if (this.state.numbersTabRecords && this.state.checkedNumberRecords) {
			await this.setStateAsync({
				isRemoveNumConfirmationDialogHidden: true,
				isFormDataSubmitted: true,
			});
			let numbers = [];
			this.state.checkedNumberRecords.forEach((res) => {
				let numObj = { numberId: res?.numberId };
				numbers.push(numObj);
			});

			const numberObj = {
				numbers: numbers,
			};

			await removeNumbers(numberObj, this._cancelToken)
				.then(async (res) => {
					if (
						res &&
						res.status === AppConfigProps.httpStatusCode.ok &&
						res.data &&
						res.data.result
					) {
						PageUtil.scrollIntoView(this.subscriberNumberListPageRef);
						await this.fetchNumbers();
						await this.setStateAsync({
							updatedNumberRecords: res.data.result.numbers,
						});
						if (
							!this.state.numbersPageRecords ||
							(this.state.numbersPageRecords &&
								this.state.numbersPageRecords.length === 0)
						) {
							await this.handlePageOffsetChange(
								UniversalConnecterConstants.TABLE_DATA.PAGE_OFFSET_DEFAULT,
							);
						}
						let successCount = 0;
						let failureCount = 0;
						if (res.data.result.numbers && res.data.result.numbers.length > 0) {
							await res.data.result.numbers.forEach((num) => {
								if (num.updateStatus?.toLowerCase().trim() === "success") {
									successCount = successCount + 1;
								}
								if (num.updateStatus?.toLowerCase().trim() === "failure") {
									failureCount = failureCount + 1;
								}
							});
						}
						let successMessage = IntlUtil.getText(
							this._intl_ns_suc_numbers,
							"notification.success.removeNumbersSuccess",
						);
						await this.setStateAsync({ successMessage: successMessage });
						await this.setStateAsync({
							numberSubmitStatus:
								UniversalConnecterConstants.FORM_SUBMIT_STATUS.SUCCESS,
						});
						await this.setStateAsync({
							numberCount: {
								successCount: successCount,
								failureCount: failureCount,
							},
						});
						await this.setStateAsync({ isFormDataSubmitted: false });
					} else {
						await this.handleResetNumbersCheck(this.state.numbersTabRecords);

						PageUtil.scrollIntoView(this.subscriberNumberListPageRef);
						let errorMessage = IntlUtil.getText(
							this._intl_ns_suc_numbers,
							"notification.error.submitNumbersFailure",
						);
						await this.setStateAsync({ errorMessage: errorMessage });
						await this.setStateAsync({
							numberSubmitStatus:
								UniversalConnecterConstants.FORM_SUBMIT_STATUS.FAILURE,
						});
						await this.setStateAsync({ isFormDataSubmitted: false });
					}
				})
				.catch(async (err) => {
					await this.handleResetNumbersCheck(this.state.numbersTabRecords);
					PageUtil.scrollIntoView(this.subscriberNumberListPageRef);
					let errorMessage = IntlUtil.getText(
						this._intl_ns_suc_numbers,
						"notification.error.submitNumbersFailure",
					);
					await this.setStateAsync({ errorMessage: errorMessage });
					const primeError = ResponseUtil.getPrimeError(err);
					if (
						err &&
						err.status &&
						err.status === AppConfigProps.httpStatusCode.badRequest
					) {
						if (primeError.code === "E10009") {
							errorMessage = IntlUtil.getText(
								this._intl_ns_suc_numbers,
								"notification.error.submitNumbersFailure",
							);
							await this.setStateAsync({ isLinkAvailable: true });
						} else {
							if (err.data && err.data.errors) {
								await this.setStateAsync({
									numberSubmitErrors: err.data.errors,
								});
							}
							await this.setStateAsync({ isLinkAvailable: true });
						}
						await this.setStateAsync({
							errorMessage: errorMessage,
						});
						await this.setStateAsync({
							numberSubmitStatus:
								UniversalConnecterConstants.FORM_SUBMIT_STATUS.FAILURE,
						});

						await this.setStateAsync({ isFormDataSubmitted: false });
					} else {
						await this.setStateAsync({ isFormDataSubmitted: false });
						await manageError(err, this.props.history);
					}
				});
		}
	};

	handlePublishNumberFormSubmit = async () => {
		await this.clearAlert();
		if (this.state.numbersTabRecords && this.state.checkedNumberRecords) {
			await this.setStateAsync({
				isPublishNumConfirmationDialogHidden: true,
				isFormDataSubmitted: true,
			});
			let numbers = [];
			this.state.checkedNumberRecords.forEach((res) => {
				let numObj = {
					numberId: res?.numberId,
				};
				numbers.push(numObj);
			});

			const numberObj = {
				numbers: numbers,
			};
			await publishNumbers(numberObj, this._cancelToken)
				.then(async (res) => {
					if (
						res &&
						res.status === AppConfigProps.httpStatusCode.ok &&
						res.data &&
						res.data.result
					) {
						PageUtil.scrollIntoView(this.subscriberNumberListPageRef);
						await this.fetchNumbers();
						await this.setStateAsync({
							updatedNumberRecords: res.data.result.numbers,
						});
						if (
							!this.state.numbersPageRecords ||
							(this.state.numbersPageRecords &&
								this.state.numbersPageRecords.length === 0)
						) {
							await this.handlePageOffsetChange(
								UniversalConnecterConstants.TABLE_DATA.PAGE_OFFSET_DEFAULT,
							);
						}
						let successMessage = IntlUtil.getText(
							this._intl_ns_suc_numbers,
							"notification.success.publishNumbersSuccess",
						);
						await this.setStateAsync({ successMessage: successMessage });
						await this.setStateAsync({
							numberSubmitStatus:
								UniversalConnecterConstants.FORM_SUBMIT_STATUS.SUCCESS,
						});
						let successCount = 0;
						let failureCount = 0;
						if (res.data.result.numbers && res.data.result.numbers.length > 0) {
							await res.data.result.numbers.forEach((num) => {
								if (num.updateStatus?.toLowerCase().trim() === "success") {
									successCount = successCount + 1;
								}
								if (num.updateStatus?.toLowerCase().trim() === "failure") {
									failureCount = failureCount + 1;
								}
							});
						}
						await this.setStateAsync({
							numberCount: {
								successCount: successCount,
								failureCount: failureCount,
							},
						});
						await this.setStateAsync({ orderNotes: null });
						await this.setStateAsync({ isFormDataSubmitted: false });
					} else {
						await this.handleResetNumbersCheck(this.state.numbersTabRecords);

						PageUtil.scrollIntoView(this.subscriberNumberListPageRef);
						let errorMessage = IntlUtil.getText(
							this._intl_ns_suc_numbers,
							"notification.error.submitNumbersFailure",
						);
						await this.setStateAsync({ errorMessage: errorMessage });
						await this.setStateAsync({
							numberSubmitStatus:
								UniversalConnecterConstants.FORM_SUBMIT_STATUS.FAILURE,
						});
						await this.setStateAsync({ isFormDataSubmitted: false });
					}
				})
				.catch(async (err) => {
					await this.handleResetNumbersCheck(this.state.numbersTabRecords);
					PageUtil.scrollIntoView(this.subscriberNumberListPageRef);
					const primeError = ResponseUtil.getPrimeError(err);
					let errorMessage = IntlUtil.getText(
						this._intl_ns_suc_numbers,
						"notification.error.submitNumbersFailure",
					);
					if (
						err &&
						err.status &&
						err.status === AppConfigProps.httpStatusCode.badRequest
					) {
						if (primeError.code === "E10009") {
							errorMessage = IntlUtil.getText(
								this._intl_ns_suc_numbers,
								"notification.error.submitNumbersFailure",
							);
							await this.setStateAsync({ isLinkAvailable: true });
						} else {
							if (err.data && err.data.errors) {
								await this.setStateAsync({
									numberSubmitErrors: err.data.errors,
								});
							}
							await this.setStateAsync({ isLinkAvailable: false });
						}

						await this.setStateAsync({
							errorMessage: errorMessage,
						});
						await this.setStateAsync({
							numberSubmitStatus:
								UniversalConnecterConstants.FORM_SUBMIT_STATUS.FAILURE,
						});

						await this.setStateAsync({ isFormDataSubmitted: false });
					} else {
						await this.setStateAsync({ isFormDataSubmitted: false });
						await manageError(err, this.props.history);
					}
				});
		}
	};

	handleDialogCancel = async () => {
		await this.setStateAsync({
			isRemoveNumConfirmationDialogHidden: true,
			isPublishNumConfirmationDialogHidden: true,
		});
	};

	handleFormFieldChange = async (e) => {
		if (e && e.target && e.target.id) {
			await this.setStateAsync({
				orderNotes: e.target.value,
			});
		}
	};

	handlePageOffsetChange = async (pageOffset) => {
		await this.setStateAsync({ isListDataFetched: false });
		if (pageOffset >= 0) {
			let slicedRecords = await ListUtil.sliceListRecords(
				this.state.numbersDisplayRecords,
				pageOffset,
				this.state.pageLimit,
			);
			await this.setStateAsync({ numbersPageRecords: slicedRecords });
			await this.setStateAsync({ searchValue: this.state.searchKeyword });
			await this.setStateAsync({ pageOffset: pageOffset });
		}
		await this.setStateAsync({ isListDataFetched: true });
	};

	handlePageLimitChange = async (pageLimit) => {
		await this.setStateAsync({ isListDataFetched: false });
		if (pageLimit > 0) {
			let pageOffset = 0;
			let slicedRecords = await ListUtil.sliceListRecords(
				this.state.numbersDisplayRecords,
				pageOffset,
				pageLimit,
			);
			await this.setStateAsync({ numbersPageRecords: slicedRecords });
			await this.setStateAsync({ searchValue: this.state.searchKeyword });
			await this.setStateAsync({ pageOffset: pageOffset });
			await this.setStateAsync({ pageLimit: pageLimit });
		}
		await this.setStateAsync({ isListDataFetched: true });
	};

	filterSubscriberNumbers = async (subscriberNumbers, tabSelected) => {
		let filteredSubscriberNumbers = [];
		if (
			subscriberNumbers &&
			subscriberNumbers.length > 0 &&
			tabSelected &&
			tabSelected.tabValue &&
			(tabSelected.tabValue.toLowerCase().trim() ===
				UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED.toLowerCase().trim() ||
				tabSelected.tabValue.toLowerCase().trim() ===
					UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED.toLowerCase().trim())
		) {
			if (
				tabSelected.tabValue ===
				UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED
			) {
				let headers = this.state.tableHeaderColumns.map((tab) => {
					if (tab.key === "checkbox") {
						tab.name = (
							<div>
								<Checkbox
									className="m-t-10"
									onChange={this.handlePublishedHeaderCheck}
									checked={this.state.publishedHeaderCheckStatus}
								/>
							</div>
						);
						return { ...tab };
					} else {
						return tab;
					}
				});
				filteredSubscriberNumbers = await _.filter(subscriberNumbers, {
					publishStatus: tabSelected.tabValue,
				});
				await this.setStateAsync({ tableHeaderColumns: headers });
			} else if (
				tabSelected.tabValue ===
				UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED
			) {
				let headers = this.state.tableHeaderColumns.map((tab) => {
					if (tab.key === "checkbox") {
						tab.name = (
							<div>
								<Checkbox
									className="m-t-10"
									onChange={this.handleUnpublishedHeaderCheck}
									checked={this.state.unpublishedHeaderCheckStatus}
								/>
							</div>
						);
						return { ...tab };
					} else {
						return tab;
					}
				});
				await this.setStateAsync({ tableHeaderColumns: headers });
				filteredSubscriberNumbers = subscriberNumbers.filter((num) => {
					return (
						num.publishStatus?.toLowerCase().trim() ===
							UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.NOT_PUBLISHED?.toLowerCase().trim() ||
						num.publishStatus?.toLowerCase().trim() ===
							UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED?.toLowerCase().trim()
					);
				});
			}

			return filteredSubscriberNumbers;
		} else {
			return subscriberNumbers;
		}
	};

	handleCarrierNumberRecords = async () => {
		let numbersTabRecords = await this.filterSubscriberNumbers(
			this.state.numbersAllRecords,
			this.state.tabSelected,
		);
		await this.setStateAsync({ numbersTabRecords: numbersTabRecords });
		let searchedRecords = await searchNumbers(
			this.state.numbersTabRecords,
			this.state.searchKeyword,
		);
		let isPublishedNumberCheckEnabled = this.state.numbersTabRecords.some(
			(x) =>
				x.publishStatus?.toLowerCase().trim() ===
					UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED?.toLowerCase().trim() &&
				x.isChecked === true,
		);
		let isUnpublishedNumberCheckEnabled = this.state.numbersTabRecords.some(
			(x) =>
				x.publishStatus?.toLowerCase().trim() ===
					(x.publishStatus?.toLowerCase().trim() ===
						UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED?.toLowerCase().trim() ||
						x.publishStatus?.toLowerCase().trim() ===
							UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.NOT_PUBLISHED?.toLowerCase().trim()) &&
				x.isChecked === true,
		);

		await this.setStateAsync({
			numbersTabRecords: searchedRecords,
			isPublishedNumberCheckEnabled: isPublishedNumberCheckEnabled,
			isUnpublishedNumberCheckEnabled: isUnpublishedNumberCheckEnabled,
		});

		let sortedRecords = await ListUtil.sortListRecords(
			searchedRecords,
			this.state.sortedColumn.fieldName,
			this.state.sortedColumn.isSortedDescending
				? UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.DESCENDING
				: UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.ASCENDING,
			this.state.sortedColumn.data,
		);
		await this.setStateAsync({ numbersDisplayRecords: sortedRecords });
		let pageOffset = 0;
		let slicedRecords = await ListUtil.sliceListRecords(
			this.state.numbersDisplayRecords,
			pageOffset,
			this.state.pageLimit,
		);
		await this.setStateAsync({ pageOffset: pageOffset });
		await this.setStateAsync({ numbersPageRecords: slicedRecords });
	};

	handlePublishLink = async () => {
		let tabSelected = _.find(this.state.numberPublishStatusTabs, {
			tabValue: UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED,
		});
		await this.setStateAsync({ tabSelected: tabSelected });
		await this.handleCarrierNumberRecords();
	};

	handleDeactivateLink = async () => {
		let tabSelected = _.find(this.state.numberPublishStatusTabs, {
			tabValue: UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED,
		});
		await this.setStateAsync({ tabSelected: tabSelected });
		await this.handleCarrierNumberRecords();
	};

	setNumberList = async (option, numberId) => {
		await this.setStateAsync((prevState) => {
			let result = [...prevState.numbersTabRecords];
			let revisedRecords = [];
			result.forEach((rec) => {
				if (rec && rec.numberId === numberId) {
					rec.isChecked = option;
				}
				revisedRecords.push({ ...rec });
				return { numbersTabRecords: revisedRecords };
			});
		});
		let headerSelectStatus = this.state.numbersTabRecords.every(
			(rec) => rec.isChecked === true,
		);
		if (
			this.state.tabSelected.tabValue?.toLowerCase().trim() ===
			UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED?.toLowerCase().trim()
		) {
			this.setStateAsync({ publishedHeaderCheckStatus: headerSelectStatus });
			let headers = this.state.tableHeaderColumns.map((tab) => {
				if (tab.key === "checkbox") {
					tab.name = (
						<div>
							<Checkbox
								className="m-t-10"
								onChange={this.handlePublishedHeaderCheck}
								checked={this.state.publishedHeaderCheckStatus}
							/>
						</div>
					);
					return { ...tab };
				} else {
					return tab;
				}
			});
			this.setStateAsync({ tableHeaderColumns: headers });
		} else {
			this.setStateAsync({ unpublishedHeaderCheckStatus: headerSelectStatus });
			let headers = this.state.tableHeaderColumns.map((tab) => {
				if (tab.key === "checkbox") {
					tab.name = (
						<div>
							<Checkbox
								className="m-t-10"
								onChange={this.handleUnpublishedHeaderCheck}
								checked={this.state.unpublishedHeaderCheckStatus}
							/>
						</div>
					);
					return { ...tab };
				} else {
					return tab;
				}
			});
			this.setStateAsync({ tableHeaderColumns: headers });
		}

		let searchedRecords = await searchNumbers(
			this.state.numbersTabRecords,
			this.state.searchKeyword,
		);

		let isPublishedNumberCheckEnabled = this.state.numbersTabRecords.some(
			(x) =>
				x.publishStatus?.toLowerCase().trim() ===
					UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED?.toLowerCase().trim() &&
				x.isChecked === true,
		);
		let isUnpublishedNumberCheckEnabled = this.state.numbersTabRecords.some(
			(x) =>
				(x.publishStatus?.toLowerCase().trim() ===
					UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED?.toLowerCase().trim() ||
					x.publishStatus?.toLowerCase().trim() ===
						UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.NOT_PUBLISHED?.toLowerCase().trim()) &&
				x.isChecked === true,
		);

		await this.setStateAsync({
			numbersTabRecords: searchedRecords,
			isPublishedNumberCheckEnabled: isPublishedNumberCheckEnabled,
			isUnpublishedNumberCheckEnabled: isUnpublishedNumberCheckEnabled,
		});
		let isHeaderChecked = this.state.numbersTabRecords.every(
			(x) => x.isChecked === true,
		);
		if (
			this.state.tabSelected.tabValue ===
			UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED
		) {
			await this.setStateAsync({ publishedHeaderCheckStatus: isHeaderChecked });
		} else {
			await this.setStateAsync({
				unpublishedHeaderCheckStatus: isHeaderChecked,
			});
		}
		await this.setStateAsync({ numbersTabRecords: searchedRecords });
		if (
			this.state.sortedColumn === null ||
			this.state.sortedColumn === undefined
		) {
			let sortedColumn = await this.state.tableHeaderColumns[1];
			await this.setStateAsync({ sortedColumn: sortedColumn });
		}

		let sortedRecords = await ListUtil.sortListRecords(
			searchedRecords,
			this.state.sortedColumn.fieldName,
			this.state.sortedColumn.isSortedDescending
				? UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.DESCENDING
				: UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.ASCENDING,
			this.state.sortedColumn.data,
		);
		await this.setStateAsync({
			numbersDisplayRecords: sortedRecords,
		});

		let slicedRecords = await ListUtil.sliceListRecords(
			this.state.numbersDisplayRecords,
			this.state.pageOffset,
			this.state.pageLimit,
		);
		await this.setStateAsync({ numbersPageRecords: slicedRecords });
	};

	handleTabSelect = async (item) => {
		if (item) {
			// await this.clearMessageBarState();
			if (this.state.numberPublishStatusTabs) {
				let tabSelected = await _.find(this.state.numberPublishStatusTabs, {
					tabName: item.props.headerText,
				});
				await this.setStateAsync({ tabSelected: tabSelected });
				await this.clearAlert();
			}
			let numbersTabRecords = [];
			numbersTabRecords = await this.filterSubscriberNumbers(
				this.state.numbersAllRecords,
				this.state.tabSelected,
			);
			await this.setStateAsync({ numbersTabRecords: numbersTabRecords });
			await this.handleResetNumbersCheck(this.state.numbersTabRecords);

			let searchedRecords = await searchNumbers(
				this.state.numbersTabRecords,
				this.state.searchKeyword,
			);
			let isPublishedNumberCheckEnabled = this.state.numbersTabRecords.some(
				(x) =>
					x.publishStatus?.toLowerCase().trim() ===
						UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED?.toLowerCase().trim() &&
					x.isChecked === true,
			);
			let isUnpublishedNumberCheckEnabled = this.state.numbersTabRecords.some(
				(x) =>
					(x.publishStatus?.toLowerCase().trim() ===
						UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED?.toLowerCase().trim() ||
						x.publishStatus?.toLowerCase().trim() ===
							UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.NOT_PUBLISHED?.toLowerCase().trim()) &&
					x.isChecked === true,
			);

			await this.setStateAsync({
				numbersTabRecords: searchedRecords,
				isPublishedNumberCheckEnabled: isPublishedNumberCheckEnabled,
				isUnpublishedNumberCheckEnabled: isUnpublishedNumberCheckEnabled,
			});

			let sortedRecords = await ListUtil.sortListRecords(
				searchedRecords,
				this.state.sortedColumn.fieldName,
				this.state.sortedColumn.isSortedDescending
					? UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.DESCENDING
					: UniversalConnecterConstants.TABLE_DATA.SORT_ORDER.ASCENDING,
				this.state.sortedColumn.data,
			);
			await this.setStateAsync({ numbersDisplayRecords: sortedRecords });
			let pageOffset = 0;
			let slicedRecords = await ListUtil.sliceListRecords(
				this.state.numbersDisplayRecords,
				pageOffset,
				this.state.pageLimit,
			);
			await this.setStateAsync({ pageOffset: pageOffset });
			await this.setStateAsync({ numbersPageRecords: slicedRecords });
		}
	};

	renderRemoveNumberDialog = () => {
		return (
			<Dialog
				hidden={this.state.isRemoveNumConfirmationDialogHidden}
				dialogContentProps={{
					className: "number-dialog-content-wrapper",
					type: DialogType.normal,
					showCloseButton: false,
					title: IntlUtil.getText(
						this._intl_ns_suc_numbers,
						"content.removeNumbers",
					),
					subText: (
						<>
							<Text className="m-b-10">
								{IntlUtil.getText(
									this._intl_ns_suc_numbers,
									"notification.warning.removeNumbersDialogPrefix",
								)}
							</Text>

							<Text>
								<span>
									{IntlUtil.getText(
										this._intl_ns_suc_numbers,
										"notification.warning.totalNumbersToBeRemoved",
									)}
								</span>{" "}
								:{" "}
								<span className="text-ff-bold dialog-text m-l-5">
									{this.state.checkedNumberRecords?.length}
								</span>
							</Text>
						</>
					),
				}}
			>
				<DialogFooter>
					<PrimaryButton onClick={this.handleRemoveNumberFormSubmit}>
						{IntlUtil.getText(this._intl_ns_suc_common, "content.confirm")}
					</PrimaryButton>
					<DefaultButton onClick={this.handleDialogCancel}>
						{IntlUtil.getText(this._intl_ns_suc_common, "content.cancel")}
					</DefaultButton>
				</DialogFooter>
			</Dialog>
		);
	};
	handleNumberUpdateStatusModalOpen = async () => {
		await this.setStateAsync({
			isModalOpen: true,
		});
	};

	handleItemRender = (item, index, column) => {
		switch (column.key) {
			case "phoneNumber":
				return <>+{item[column.fieldName]}</>;
			case "publishStatus":
				if (
					item.publishStatus?.toLowerCase().trim() ===
					UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED?.toLowerCase().trim()
				) {
					return "Yes";
				} else {
					return "No";
				}

			case "checkbox":
				if (
					item?.publishStatus ===
					UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED
				) {
					return (
						<>
							<Checkbox
								checked={item["isChecked"]}
								onChange={(e, option) =>
									this.handlePublishedNumberCheck(
										e,
										option,
										item.numberId,
										item?.publishStatus,
									)
								}
							></Checkbox>
						</>
					);
				} else {
					return (
						<>
							<Checkbox
								checked={item["isChecked"]}
								onChange={(e, option) =>
									this.handleUnpublishedNumberCheck(
										e,
										option,
										item.numberId,
										item?.publishStatus,
									)
								}
							></Checkbox>
						</>
					);
				}
			default:
				return <>{item[column.fieldName]}</>;
		}
	};

	handleRefresh = async () => {
		const subscriberId = this.props.match.params.sid;
		await this.setStateAsync({ isFormDataSubmitted: true });
		await this.fetchNumbers(subscriberId);
		await this.setStateAsync({ isFormDataSubmitted: false });
	};

	handleModalClose = () => {
		this.setState({ isModalOpen: false });
	};

	handlePublishedNumberCheck = async (e, option, numberId, publishStatus) => {
		if (this.state.numbersTabRecords) {
			await this.clearAlert();
			let unpublishedNumbers = [];
			await this.state.numbersAllRecords.forEach((rec) => {
				if (
					rec.publishStatus?.toLowerCase().trim() !==
						publishStatus?.toLowerCase().trim() &&
					rec.isChecked === true
				) {
					unpublishedNumbers.push(rec.publishStatus);
				}
			});

			if (unpublishedNumbers.length === 0) {
				await this.setNumberList(option, numberId);
			} else {
				if (
					this.state.tabSelected?.tabName ===
					UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.ALL
				) {
					PageUtil.scrollIntoView(this.subscriberNumberListPageRef);
					await this.setStateAsync({
						numberSubmitStatus:
							UniversalConnecterConstants.FORM_SUBMIT_STATUS.FAILURE,
						errorMessage: IntlUtil.getText(
							this._intl_ns_suc_numbers,
							"notification.error.publishedNumberSelectFailure",
						),
					});
				} else {
					await this.setNumberList(option, numberId);
				}
			}
		}
	};
	handleUnpublishedNumberCheck = async (e, option, numberId, publishStatus) => {
		if (this.state.numbersTabRecords) {
			await this.clearAlert();
			let publishedNumbers = [];
			await this.state.numbersAllRecords.forEach((rec) => {
				rec.publishStatus =
					rec.publishStatus?.toLowerCase().trim() ===
					UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.NOT_PUBLISHED.toLowerCase().trim()
						? "unpublished"
						: rec.publishStatus;
				if (
					rec.publishStatus?.toLowerCase().trim() !==
						publishStatus?.toLowerCase().trim() &&
					rec.isChecked === true
				) {
					publishedNumbers.push(rec.publishStatus);
				}
			});
			if (publishedNumbers.length === 0) {
				await this.setNumberList(option, numberId);
			} else {
				if (
					this.state.tabSelected?.tabName ===
					UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.ALL
				) {
					PageUtil.scrollIntoView(this.subscriberNumberListPageRef);
					await this.setStateAsync({
						numberSubmitStatus:
							UniversalConnecterConstants.FORM_SUBMIT_STATUS.FAILURE,
						errorMessage: IntlUtil.getText(
							this._intl_ns_suc_numbers,
							"notification.error.unpublishedNumberSelectFailure",
						),
					});
				} else {
					await this.setNumberList(option, numberId);
				}
			}
		}
	};

	renderPublishNumberDialog = () => {
		return (
			<Dialog
				hidden={this.state.isPublishNumConfirmationDialogHidden}
				dialogContentProps={{
					className: "number-dialog-content-wrapper",
					type: DialogType.normal,
					showCloseButton: false,
					title: IntlUtil.getText(
						this._intl_ns_suc_numbers,
						"content.publishNumbers",
					),
					subText: (
						<>
							<Text className="m-b-20">
								{IntlUtil.getText(
									this._intl_ns_suc_numbers,
									"notification.warning.publishNumbersDialogPrefix",
								)}
							</Text>

							<Text>
								<span>
									{IntlUtil.getText(
										this._intl_ns_suc_numbers,
										"notification.warning.totalNumbersToBePublished",
									)}
								</span>{" "}
								:{" "}
								<span className="text-ff-bold dialog-text m-l-5">
									{this.state.checkedNumberRecords?.length}
								</span>
							</Text>
						</>
					),
				}}
			>
				<DialogFooter>
					<PrimaryButton onClick={this.handlePublishNumberFormSubmit}>
						{IntlUtil.getText(this._intl_ns_suc_common, "content.confirm")}
					</PrimaryButton>
					<DefaultButton onClick={this.handleDialogCancel}>
						{IntlUtil.getText(this._intl_ns_suc_common, "content.cancel")}
					</DefaultButton>
				</DialogFooter>
			</Dialog>
		);
	};

	handleHeaderChange = (headerProps) => {
		if (headerProps && headerProps && headerProps.columns) {
			if (
				this.state.tabSelected &&
				(this.state.tabSelected.tabValue ===
					UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.ALL)
			) {
				headerProps.columns = headerProps.columns.map((rec) => {
					if (rec && rec.key === "checkbox") {
						rec.name = <span className="m-l-5"></span>;
						return { ...rec };
					} else {
						return rec;
					}
				});
			} else {
				headerProps.columns = headerProps.columns.filter((rec) => {
					if (rec && rec.key) {
						return true;
					} else {
						return false;
					}
				});
			}
		}
		return headerProps;
	};

	loadZoomSigninPage = () => {
		return this.props.history.push(AppURLProps.zoomSignin);
	};

	render() {
		return (
			<>
				<AppPageTitle
					pageTitle={IntlUtil.getText(
						this._intl_ns_suc_numbers,
						"title.zoomNumbers",
					)}
				/>

				<div id="suc-page-container">
					<div className="page-header-container">
						<div className={`page-main-header-wrapper`}>
							<div className="zoom-main-header-content-wrapper">
								<div className="main-header-title">
									{IntlUtil.getText(
										this._intl_ns_suc_numbers,
										"content.zoomNumbers",
									)}
								</div>
								<Pivot
									className="frame-header-numbers-list-tabs m-l-25"
									selectedKey={this.state.tabSelected?.tabName}
									onLinkClick={(props) => {
										this.handleTabSelect(props);
									}}
									headersOnly={false}
									linkFormat="tabs"
								>
									{this.state.numberPublishStatusTabs.map((tab, index) => {
										return (
											<PivotItem
												key={`key-subscriber-number-tab-${index}`}
												headerText={tab.tabName}
												itemKey={tab.tabName}
												headerButtonProps={{
													disabled:
														!this.state.isPageDataFetched ||
														this.state.isFormDataSubmitted,
												}}
											/>
										);
									})}
								</Pivot>
							</div>
							<div className="main-header-actions">
								<SearchBox
									placeholder={IntlUtil.getText(
										this._intl_ns_suc_numbers,
										"content.searchNumbers",
									)}
									value={this.state.searchValue}
									onChange={(e) => this.handleSearchChange(e)}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											this.handleSearchSubmit(e);
										}
									}}
									onClear={async (e) => {
										await this.handleSearchCancel(e);
									}}
									disabled={this.state.isFormDataSubmitted}
									className={`page-search-box`}
								/>
							</div>
						</div>

						<PageOverlay hidden={!this.state.isFormDataSubmitted} />
						<ProgressIndicator
							progressHidden={!this.state.isFormDataSubmitted}
							className="page-frame-progress-indicator"
						/>
						{/* {!this.state.isListDataFetched ? (
							<div className="page-frame-separator"></div>
						) : null} */}
					</div>

					<PageLoader
						status={this.state.isPageDataFetched}
						size="medium"
						labelPosition="below"
						label={IntlUtil.getText(
							this._intl_ns_common,
							"content.loadingInprogress",
						)}
						type="full"
					>
						<div className="page-frame-content frame-content-number-subscriber-numbers-list">
							{this.state.numbersAllRecords &&
							this.state.numbersAllRecords.length > 0 &&
							this.state.numbersDisplayRecords &&
							this.state.numbersDisplayRecords.length > 0 &&
							this.state.numbersPageRecords &&
							this.state.numbersPageRecords.length > 0 ? (
								<div className="page-frame-list">
									<div className="page-frame-table">
										<ScrollablePane
											scrollbarVisibility={ScrollbarVisibility.always}
										>
											<div
												className="page-frame-notification"
												ref={this.subscriberNumberListPageRef}
											>
												{this.state.numberSubmitStatus ===
												UniversalConnecterConstants.FORM_SUBMIT_STATUS
													.FAILURE ? (
													<MessageBar
														messageBarType={MessageBarType.error}
														className="notification-messagebar"
													>
														<span className="text-ff-semibold">
															{this.state.errorMessage}{" "}
															{this.state.isLinkAvailable === true ? (
																<Link onClick={this.loadZoomSigninPage}>
																	{IntlUtil.getText(
																		this._intl_ns_suc_numbers,
																		"content.clickHereToSignin",
																	)}
																</Link>
															) : null}
														</span>
														{this.state.numberSubmitErrors &&
														this.state.numberSubmitErrors.length > 0 ? (
															<span>
																{this.state.numberSubmitErrors.map(
																	(error, index) => {
																		return (
																			<div
																				key={`key-numberlist-error-${index}`}
																			>
																				{error.message}
																			</div>
																		);
																	},
																)}
															</span>
														) : null}
													</MessageBar>
												) : null}

												{this.state.numberSubmitStatus ===
												UniversalConnecterConstants.FORM_SUBMIT_STATUS
													.SUCCESS ? (
													<MessageBar
														messageBarType={MessageBarType.info}
														className="notification-messagebar"
													>
														{" "}
														<span className="text-ff-semibold">
															{this.state.successMessage} Success:{" "}
															{this.state.numberCount.successCount}, Failure:{" "}
															{this.state.numberCount.failureCount}.
														</span>{" "}
														<Link
															onClick={this.handleNumberUpdateStatusModalOpen}
														>
															{IntlUtil.getText(
																this._intl_ns_suc_numbers,
																"content.viewStatus",
															)}
														</Link>
													</MessageBar>
												) : null}
											</div>

											<DetailsList
												columns={this.state.tableHeaderColumns}
												items={this.state.numbersPageRecords}
												compact={false}
												selectionMode={SelectionMode.none}
												layoutMode={DetailsListLayoutMode.justified}
												isHeaderVisible={true}
												onColumnHeaderClick={this.handleSortChange}
												selectionPreservedOnEmptyClick={true}
												onRenderItemColumn={this.handleItemRender}
												onRenderDetailsHeader={(
													detailsHeaderProps,
													defaultRender,
												) => {
													let headerProps =
														this.handleHeaderChange(detailsHeaderProps);

													return (
														<Sticky
															stickyPosition={StickyPositionType.Header}
															isScrollSynced
															stickyClassName="page-frame-table-header-sticky"
														>
															{defaultRender(headerProps)}
														</Sticky>
													);
												}}
												constrainMode={ConstrainMode.unconstrained}
											/>
										</ScrollablePane>
									</div>
									<div className="page-frame-separator"></div>
									<div className="number-page-frame-actions">
										<div className="dt-number-page-btn-wrapper">
											{this.state.tabSelected.tabValue.toLowerCase().trim() !==
											UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.PUBLISHED.toLowerCase().trim() ? (
												<DefaultButton
													disabled={
														this.state.isFormDataSubmitted ||
														!this.state.isUnpublishedNumberCheckEnabled
													}
													primary
													className="m-r-10 m-b-10"
													onClick={this.handlePublishNumberCheck}
												>
													{IntlUtil.getText(
														this._intl_ns_suc_numbers,
														"content.publish",
													)}
												</DefaultButton>
											) : null}

											{this.state.tabSelected.tabValue.toLowerCase().trim() !==
											UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.UNPUBLISHED.toLowerCase().trim() ? (
												<DefaultButton
													disabled={
														this.state.isFormDataSubmitted ||
														!this.state.isPublishedNumberCheckEnabled
													}
													primary
													className="m-r-10 m-b-10"
													onClick={this.handleRemoveNumberCheck}
												>
													{IntlUtil.getText(
														this._intl_ns_suc_numbers,
														"content.remove",
													)}
												</DefaultButton>
											) : null}
										</div>
										<TablePagination
											pageOffset={this.state.pageOffset}
											pageLimit={this.state.pageLimit}
											totalRecords={this.state.numbersDisplayRecords.length}
											pageRecordsLength={this.state.numbersPageRecords.length}
											handlePageOffsetChange={this.handlePageOffsetChange}
											handlePageLimitChange={this.handlePageLimitChange}
										/>
									</div>
									{this.renderRemoveNumberDialog()}
									{this.renderPublishNumberDialog()}
								</div>
							) : (
								<div className="page-frame-no-data">
									{this.state.numberSubmitStatus ===
									UniversalConnecterConstants.FORM_SUBMIT_STATUS.SUCCESS ? (
										<MessageBar
											messageBarType={MessageBarType.info}
											className="notification-messagebar number-notification-messagebar"
										>
											{" "}
											<span className="text-ff-semibold">
												{this.state.successMessage} Success:{" "}
												{this.state.numberCount.successCount}, Failure:{" "}
												{this.state.numberCount.failureCount}.
											</span>{" "}
											<Link onClick={this.handleNumberUpdateStatusModalOpen}>
												{IntlUtil.getText(
													this._intl_ns_suc_numbers,
													"content.viewStatus",
												)}
											</Link>
										</MessageBar>
									) : null}
									<div>
										{this.state.tabSelected &&
										this.state.tabSelected.tabName ===
											UniversalConnecterConstants.NUMBER.PUBLISH_STATUS
												.PUBLISHED ? (
											<>
												{IntlUtil.getText(
													this._intl_ns_suc_numbers,
													"notification.info.noPublishedNumbersFound",
												)}
											</>
										) : null}

										{this.state.tabSelected &&
										this.state.tabSelected.tabName ===
											UniversalConnecterConstants.NUMBER.PUBLISH_STATUS
												.UNPUBLISHED ? (
											<>
												{IntlUtil.getText(
													this._intl_ns_suc_numbers,
													"notification.info.noUnpublishedNumbersFound",
												)}
											</>
										) : null}
										{this.state.tabSelected &&
										this.state.tabSelected.tabName ===
											UniversalConnecterConstants.NUMBER.PUBLISH_STATUS.ALL ? (
											<>
												{IntlUtil.getText(
													this._intl_ns_suc_numbers,
													"notification.info.noNumbersFound",
												)}
											</>
										) : null}
									</div>
								</div>
							)}
						</div>
						<NumberUpdateStatusModal
							intlNamespace={this._intl_ns_suc_numbers}
							tableHeaderColumns={NumberUpdateStatusTableColumns(
								this._intl_ns_suc_numbers,
							)}
							updatedNumberRecords={this.state.updatedNumberRecords}
							isModalOpen={this.state.isModalOpen}
							isModalClose={this.handleModalClose}
						/>
					</PageLoader>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({});
const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(NumberList);
