/**
Project: Phone Connect (c)
Title: Phone Connect URLs
Description: URLs of the Phone Connect
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
export const UniversalConnecterURLProps = {
  index: "/",
  home: "/",
  zoomSignin:'/zoom-signin',
  zoomAuthenticate:'/zoom-authenticate',
  pageNotFound: "/page-not-found",
  page404: "/404",
  numberManagement:{
    numberList:"/numbers"
  }
};
