import { AppConfigProps } from "../settings/app-config";

class SessionTimer {
  constructor({ timeout, onTimeout }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.expireTime = this.updateExpiredTime.bind(this);
    this.eventHandler = this.extendTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(
        localStorage.getItem(
          AppConfigProps.identitySession.universalConnecterSessionTimerKey
        ),
        10
      );
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000 * 60); // 1 Minute (60 sec) for start Interval
  }
  tracker() {
    window.addEventListener("click", this.eventHandler);
  }

  extendTime() {
    let expiredTime = parseInt(
      localStorage.getItem(
        AppConfigProps.identitySession.universalConnecterSessionTimerKey
      ),
      10
    );
    if (expiredTime) {
      this.timeoutTracker = setTimeout(() => {
        localStorage.setItem(
          AppConfigProps.identitySession.universalConnecterSessionTimerKey,
          expiredTime + 60000
        );
      }, 300);
    }
  }

  updateExpiredTime() {
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem(
        AppConfigProps.identitySession.universalConnecterSessionTimerKey,
        Date.now() + this.timeout * 1000
      );
    }, 300);
  }

  cleanUp() {
    localStorage.removeItem(
      AppConfigProps.identitySession.universalConnecterSessionTimerKey
    );
    clearInterval(this.interval);
  }
}

export default SessionTimer;
