/**
Project: Phone Connect (c)
Title: Blank Layout  
Description: Component for hadling the routes of non logged-in pages
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Signin from "../core/components/signin";
import ErrorPage from "../core/views/error-page";
import Error404 from "../core/views/error-404";
import PageNotFound from "../core/views/error-page-not-found";
import SessionExpired from "../core/views/error-session-expired";
import ZoomSignin from "../core/components/zoom-signin";
import ZoomAuthenticate from "../core/components/zoom-authenticate";
import Signout from "../core/components/signout";
import authenticate from "../core/components/authenticate";



class BlankLayout extends Component {
  render() {
    return (
      <Switch>
        <Route path="/signin" exact component={Signin} />
        <Route path="/authenticate" exact component={authenticate} />
        <Route path="/zoom-signin" exact component={ZoomSignin} />
        <Route path="/zoom-authenticate" exact component={ZoomAuthenticate} />
        <Route path="/signout" component={Signout} />
        <Route path="/error" exact component={ErrorPage} />
        <Route path="/404" exact component={Error404} />
        <Route path="/session-expired" exact component={SessionExpired} />
        <Route path="/page-not-found" exact component={PageNotFound} />
      </Switch>
    );
  }
}

export default BlankLayout;
