/**
Project: Phone Connect (c)
Title: Identity Actions 
Description: Identity actions for user login, session validation, privilege checks
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import api from "../helpers/api-services";
import { AppConfigProps } from "../settings/app-config";
import { getSessionExpiredError, logMessage } from "./common-actions";
import TokenUtil from "../helpers/token-util";
import { IDENTITY_PROFILE, LOGIN_TYPE } from "../../store/constants";
import RequestUtil from "../helpers/request-util";

const actionFileName = "identity-actions.js";

// User Basic Browser Form Signin Authentication

// User Token Teams  Signin Authentication
export function isIdentitySessionValid() {
  const tokenData = TokenUtil.getIdentitySippioAccessTokenData();
  if (
    tokenData &&
    tokenData?.userName &&
    tokenData?.userEmail &&
    tokenData?.tokenExpiry >= Date.now() / 1000
  ) {
    return true;
  } else {
    return false;
  }
}
export function isZoomIdentitySessionValid() {
  const tokenData = TokenUtil?.getIdentitySippioZoomAccessTokenData();
  if (
    tokenData !== null &&
    tokenData !== undefined &&
    tokenData?.userName &&
    tokenData?.zoomAccountEmail &&
    tokenData?.zoomTokenExpiry >= Date.now() / 1000
  ) {
    return true;
  } else {
    return false;
  }
}

//check Session
export function checkIdentitySession() {
  const tokenData = TokenUtil.getIdentitySippioAccessTokenData();
  if (
    tokenData &&
    tokenData?.userEmail &&
    tokenData?.tokenExpiry >= Date.now() / 1000
  ) {
    if (
      tokenData.tokenExpiry - parseInt(Date.now() / 1000) <=
      AppConfigProps.identitySession.expiryCheckRemainingSeconds
    ) {
      const _axiosSource = api.CancelToken.source();
      const _cancelToken = { cancelToken: _axiosSource.token };
      renewIdentitySession(_cancelToken);
    }

    return true;
  } else {
    return false;
  }
}

export function checkZoomIdentitySession() {
  const tokenData = TokenUtil.getIdentitySippioZoomAccessTokenData();
  if (
    tokenData !== null &&
    tokenData !== undefined &&
    tokenData?.zoomAccountEmail &&
    tokenData?.zoomTokenExpiry >= Date.now() / 1000
  ) {
    if (
      tokenData?.zoomTokenExpiry - parseInt(Date.now() / 1000) <=
      AppConfigProps.identitySession.expiryCheckRemainingSeconds
    ) {
      const _axiosSource = api.CancelToken.source();
      const _cancelToken = { cancelToken: _axiosSource.token };
      renewIdentitySession(_cancelToken);
    }

    return true;
  } else {
    return false;
  }
}

// Renew user token
export function renewIdentitySession(cancelToken) {
  return new Promise(async function (resolve, reject) {
    const refreshToken = TokenUtil.getIdentitySippioRefreshToken();
    if (refreshToken) {
      await api
        .post(
          AppConfigProps.universalConnecterAPIPrefix +
            "/authentication/token/renew",
          { refreshToken: refreshToken },
          cancelToken
        )
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.result &&
            res.data.result.accessToken
          ) {
            await TokenUtil.setIdentityToken(
              res.data.result.accessToken,
              res.data.result.refreshToken
            );
            await RequestUtil.setRequestHeaders();
          } else {
            //reject(new Error());
          }
        })
        .catch((err) => {
          if (api.isCancel(err)) {
            logMessage(
              AppConfigProps.log.severityLevel.warning,
              actionFileName + ">renewIdentitySession()",
              AppConfigProps.httpStatusCode.clientClosedRequest,
              err.message
            );
          } else {
            //reject(err.response);
          }
        });
    } else {
      //reject(new Error());
    }
  });
}

export function authenticateTokenSignin(token, data, cancelToken) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return new Promise(async function (resolve, reject) {
    api
      .post(
        AppConfigProps.universalConnecterAPIPrefix + "/authentication",
        null,
        { headers: headers },
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">authenticateTokenSignin()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function setIdentityProfile(identityProfile) {
  return async function (dispatch) {
    try {
      dispatch({
        type: IDENTITY_PROFILE,
        payload: identityProfile,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function authenticateZoomTokenSignin(code, cancelToken) {
  return new Promise(async function (resolve, reject) {
    let identitySession = checkIdentitySession();
    if (identitySession === true) {
      api
        .post(
          AppConfigProps.universalConnecterAPIPrefix + "/authentication/token",
          code,
          cancelToken
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if (api.isCancel(err)) {
            logMessage(
              AppConfigProps.log.severityLevel.warning,
              actionFileName + ">authenticateTokenSignin()",
              AppConfigProps.httpStatusCode.clientClosedRequest,
              err.message
            );
          } else {
            reject(err.response);
          }
        });
    } else {
      reject(getSessionExpiredError());
    }
  });
}

export function authenticateZoomAccessTokenSignin(code, cancelToken) {
  return new Promise(async function (resolve, reject) {
    api
      .post(
        AppConfigProps.universalConnecterAPIPrefix + "/authentication/access",
        code,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">authenticateZoomAccessTokenSignin()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

//login Type
export function setLoginType(loginType) {
  return async function (dispatch) {
    try {
      dispatch({
        type: LOGIN_TYPE,
        payload: loginType,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}
