import IntlUtil from "../../../core/helpers/intl-util";
import { Text } from "@fluentui/react";

export const NumberUpdateStatusTableColumns = (intlNamespace) => {
  return [
    {
      key: "phoneNumber",
      name: (
        <div>
          <Text className="m-r-5">
            {IntlUtil.getText(intlNamespace, "content.phoneNumber")}
          </Text>
        </div>
      ),
      fieldName: "phoneNumber",
      data: "string",
      // minWidth: 250,
      //   minWidth: 150,
      //   maxWidth: 450,
      minWidth: 140,
      maxWidth: 140,
      isRowHeader: true,
      isResizable: true,
      isSortable: true,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: "updateStatus",
      name: (
        <div>
          <Text className="m-r-5">
            {IntlUtil.getText(intlNamespace, "content.status")}
          </Text>
        </div>
      ),
      fieldName: "updateStatus",
      data: "string",
      //   minWidth: 250,
      //   maxWidth: 300,
      minWidth: 140,
      maxWidth: 160,
      isRowHeader: true,
      isResizable: true,
      isSortable: true,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: "errorMessage",
      name: (
        <div>
          <Text className="m-r-5">
            {IntlUtil.getText(intlNamespace, "content.errorMessage")}
          </Text>
        </div>
      ),
      fieldName: "errorMessage",
      data: "string",
      //   minWidth: 250,
      //   maxWidth: 300,
      minWidth: 180,
      maxWidth: 250,
      isRowHeader: true,
      isResizable: true,
      isSortable: true,
      isSorted: false,
      isSortedDescending: false,
    },
  ];
};
