/**
Project: Phone Connect (c)
Title: Signin 
Description: Component for displaying the sign in page 
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import React, { Component } from "react";
import { connect } from "react-redux";
import api from "../helpers/api-services";
import IntlUtil from "../helpers/intl-util";
import { AppPageTitle } from "./app-page-title";
import { UniversalConnecterURLProps } from "../../pages/common/settings/universal-connecter-urls";
import { AppURLProps } from "../settings/app-urls";
import {
  authenticateTokenSignin,
  setIdentityProfile,
} from "../actions/identity-actions";
import { AppConfigProps } from "../settings/app-config";
import ResponseUtil from "../helpers/response-util";
import TokenUtil from "../helpers/token-util";
import PageLoader from "../../pages/common/helpers/page-loader";
import TelemetryUtil from "../helpers/telemetry-util";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../settings/msal-config";

class Authenticate extends Component {
  _isMounted = false;
  _axiosSource = api.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _intl_ns_common = "common";
  _intl_ns_signin = "signin";

  constructor(props) {
    super(props);
    this.state = {
      isFormDataSubmitted: false,
      errorMessage: null,
      showAccounts: false,
      isErrorOccurred: false,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    await this.setStateAsync({ isFormDataSubmitted: false });
    await this.initializeGetSignin();
    await this.setStateAsync({ isFormDataSubmitted: true });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      IntlUtil.getText(
        this._intl_ns_common,
        "notification.warning.requestCancelled"
      )
    );
  }

  initializeGetSignin = async () => {
    let msalConfigObj = msalConfig;
    let loginDetails = JSON.parse(localStorage.getItem("loginDetailsHint"));
    if (loginDetails?.loginProvider?.toLowerCase() !== "microsoft.com") {
      let azureActiveDirectoryClientAppId =
        process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_B2C_APP_ID;
      let azureActiveDirectoryB2CMicrosoftAuthority =
        process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_MICROSOFT_B2C_URL;
      msalConfigObj = {
        ...msalConfig,
        auth: {
          ...msalConfig.auth,
          clientId: azureActiveDirectoryClientAppId,
          authority: azureActiveDirectoryB2CMicrosoftAuthority,
        },
      };
    } else {
      let azureActiveDirectoryClientAppId =
        process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_ID;
      let azureActiveDirectoryMicrosoftAuthority =
        process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_MICROSOFT_URL;
      msalConfigObj = {
        ...msalConfig,
        auth: {
          ...msalConfig.auth,
          clientId: azureActiveDirectoryClientAppId,
          authority: azureActiveDirectoryMicrosoftAuthority,
        },
      };
    }
    let msalContext = new PublicClientApplication(msalConfigObj);

    msalContext
      .handleRedirectPromise(window.location.hash)
      .then(async (response) => {
        if (response.accessToken) {
          await this.handleInteractiveSignin(
            response.accessToken,
            response.accessToken
          );
        } else {
          await this.handleInteractiveSignin(
            response.idToken,
            response.idToken
          );
        }
      })
      .catch(async (err) => {
        await this.setStateAsync({
          isFormDataSubmitted: false,
        });
        let errorMessage = IntlUtil.getText(
          this._intl_ns_signin,
          "notification.error.signinIssueUnknown"
        );
        await this.props.history.push({
          pathname: AppURLProps.signin,
          state: {
            errorMessage: errorMessage,
            isErrorOccurred: true,
          },
        });
        TelemetryUtil.logException(err);
      });
  };

  handleInteractiveSignin = async (accessToken, refreshToken) => {
    await authenticateTokenSignin(
      accessToken,
      { refreshToken: refreshToken },
      this._cancelToken
    )
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ isFormDataSubmitted: false });
          if (res.data.result.accessToken && res.data.result.refreshToken) {
            TokenUtil.setIdentityToken(
              res.data.result.accessToken,
              res.data.result.refreshToken
            );
            await this.props.history.push(UniversalConnecterURLProps.home);
          }
        }
      })
      .catch(async (err) => {
        await this.setStateAsync({ isFormDataSubmitted: false });
        await this.setStateAsync({
          isErrorOccurred: true,
        });
        //access log failure
        let errorMessage = IntlUtil.getText(
          this._intl_ns_signin,
          "notification.error.signinIssueUnknown"
        );
        if (err && err.status) {
          if (err.status === AppConfigProps.httpStatusCode.badRequest) {
            let primeError = ResponseUtil.getPrimeError(err);
            if (primeError && primeError.code === "E10003") {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.accountNotFound"
              );
            } else {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.signinIssueUnknown"
              );
            }
          } else if (
            err.status === AppConfigProps.httpStatusCode.unauthorized
          ) {
            let primeError = ResponseUtil.getPrimeError(err);
            if (primeError && primeError.code === "E10003") {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.accountNotExist"
              );
            } else if (
              primeError &&
              (primeError.code === "E10022" || primeError.code === "E10021")
            ) {
              errorMessage = primeError.message;
            } else {
              errorMessage = IntlUtil.getText(
                this._intl_ns_signin,
                "notification.error.signinIssueUnknown"
              );
            }
          } else {
            errorMessage = IntlUtil.getText(
              this._intl_ns_signin,
              "notification.error.signinIssueUnknown"
            );
          }
        }
        await this.props.history.push({
          pathname: AppURLProps.signin,
          state: {
            errorMessage: errorMessage,
            isErrorOccurred: true,
          },
        });
      });
  };

  render() {
    return (
      <div className="signin-page-wrapper">
        <AppPageTitle
          pageTitle={IntlUtil.getText(this._intl_ns_signin, "title.signin")}
        />
        <PageLoader
          status={!this.state.isFormDataSubmitted}
          size="medium"
          labelPosition="bottom"
          label={IntlUtil.getText(
            this._intl_ns_common,
            "content.loadingInprogress"
          )}
          type="full"
        >
          <div></div>
        </PageLoader>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginType: state.identityStore.loginType,
});
const mapActionToProps = { setIdentityProfile };

export default connect(mapStateToProps, mapActionToProps)(Authenticate);
